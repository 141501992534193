import React, { useRef, useState, useEffect } from "react";
import { Home, LogOut } from "react-feather";
import { Link, useNavigate } from "react-router-dom";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import TeacherService from '../../../Api/Services/TeacherService';
import UserService from "../../../Api/Services/UserService";
import {

  logo,

} from "../../imagepath";

// eslint-disable-next-line react/prop-types
export function InstructorHeader() {
  const [navbar, setNavbar] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [mobileMenu, setMobileMenu] = useState(false);
  const openMobileMenu = () => {
    document.body.classList.add("menu-opened");
    setMobileMenu(true);
  };
  const hideMobileMenu = () => {
    document.body.classList.remove("menu-opened");
    setMobileMenu(false);
  };
  const profile = useRef();
  useOnClickOutside(profile, () => setShowProfile(false));
  const profileClick = (e) => {
    e.preventDefault();
    setShowProfile(!showProfile);
  };

  const changeHeaderBackground = () => {
    if (window.scrollY >= 60) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  window.addEventListener("scroll", changeHeaderBackground);
  const [profiledata, setProfiledata] = useState('');

  var imageUrl = process.env.REACT_APP_URL;
  useEffect(() => {
    if (localStorage.getItem('usertoken')) {

      handleTeacherProfile()
    }
  }, [])
  const navigate = useNavigate();
  const handleTeacherProfile = async () => {
    try {

      let responseData = await TeacherService.teacher_profile().json()

      if (responseData.status === true) {
        setProfiledata(responseData)



      }

    } catch (error) {
      console.log(error)
    }

  }
  const handleLogout = async () => {
    try {



      const data = await UserService.user_logout().json();
      if (data.status === true) {
        localStorage.removeItem("usertoken");
        localStorage.removeItem("user");
        navigate('/');
      }




    } catch (error) {
      console.log(error)
    }
  }
  return (
    <header className="header header-page theme-header d-none">
      <div className="header-fixed">
        <nav
          className={
            navbar
              ? "navbar navbar-expand-lg header-nav scroll-sticky add-header-bg"
              : "navbar navbar-expand-lg header-nav scroll-sticky"
          }
        >
          <div className="container-fluid ">
            <div className="navbar-header">
              <Link
                id="mobile_btn"
                to="#;"
                onClick={openMobileMenu}
              >
                <span className="bar-icon">
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
              </Link>
              <Link  className="navbar-brand logo">
                <img src={logo} className="img-fluid" alt="Logo" />
              </Link>
            </div>
            <div className="main-menu-wrapper">
              <div className="menu-header">
                <Link  className="menu-logo">
                  <img src={logo} className="img-fluid" alt="Logo" />
                </Link>
                <Link
                  id="menu_close"
                  className="menu-close"
                  to="#;"
                  onClick={hideMobileMenu}
                >
                  <i className="fas fa-times"></i>
                </Link>
              </div>
              <ul className="main-nav">
                <li className="has-submenu">
                  <Link to="/" >
                    Home
                  </Link>

                </li>
                <li className="has-submenu active">
                  <Link to="/teacher-dashboard">
                    Teacher

                  </Link>

                </li>
                {/* <li className="has-submenu active">
                  <Link to="/teacher-dashboard">
                    Teacher

                  </Link>

                </li>
                <li className="has-submenu">
                  <Link to="/student-dashboard">
                    Student

                  </Link>

                </li> */}



                <li className="login-link">
                  <Link to="/login" onClick={hideMobileMenu}>
                    Login / Signup
                  </Link>
                </li>
              </ul>
            </div>
            <ul className="nav header-navbar-rht">
              <li className="nav-item nav-search">
              <div className='comon-search-group'>
                          <span className='search-icon'>
                            
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.66536 14.0002C11.1632 14.0002 13.9987 11.1646 13.9987 7.66683C13.9987 4.16903 11.1632 1.3335 7.66536 1.3335C4.16756 1.3335 1.33203 4.16903 1.33203 7.66683C1.33203 11.1646 4.16756 14.0002 7.66536 14.0002Z" stroke="#969BAB" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M14.6654 14.6668L13.332 13.3335" stroke="#969BAB" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

                          </span>
                          <input type="text" className="form-control" placeholder="Search"></input>

                        </div>

              </li>
            <li className="nav-item noti-nav">
              <span className="noti-new">

              </span>
              <Link className="dropdown-item " onClick={handleLogout}>

              <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.018 3.16748C7.98388 3.16748 5.51805 5.63331 5.51805 8.66748V11.3166C5.51805 11.8758 5.27971 12.7283 4.99555 13.205L3.94138 14.9558C3.29055 16.0375 3.73971 17.2383 4.93138 17.6416C8.88221 18.9616 13.1447 18.9616 17.0955 17.6416C18.2047 17.275 18.6905 15.9641 18.0855 14.9558L17.0314 13.205C16.7564 12.7283 16.518 11.8758 16.518 11.3166V8.66748C16.518 5.64248 14.043 3.16748 11.018 3.16748Z" stroke="#61677A" strokeWidth="1.8" strokeMiterlimit="10" strokeLinecap="round"/>
<path d="M12.7139 3.43332C12.4298 3.35082 12.1364 3.28665 11.8339 3.24999C10.9539 3.13999 10.1106 3.20415 9.32227 3.43332C9.5881 2.75499 10.2481 2.27832 11.0181 2.27832C11.7881 2.27832 12.4481 2.75499 12.7139 3.43332Z" stroke="#61677A" strokeWidth="1.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M13.7676 17.9717C13.7676 19.4842 12.5301 20.7217 11.0176 20.7217C10.2659 20.7217 9.56924 20.41 9.07424 19.915C8.57924 19.42 8.26758 18.7233 8.26758 17.9717" stroke="#61677A" strokeWidth="1.8" strokeMiterlimit="10"/>
</svg>


                </Link>
                

              </li>



              <li className="nav-item user-nav">
                <Link
                  to="#"
                  className={
                    showProfile ? "dropdown-toggle show" : "dropdown-toggle"
                  }
                  data-bs-toggle="dropdown"
                  onClick={profileClick}
                >
                  <span className="user-img">
                    {profiledata.image ? <img src={imageUrl + "/User/" + profiledata.image} alt='' /> : null}
                    <span className="status online"><i className="fas fa-angle-down"></i></span>
                  </span>
                </Link>
                <div
                  ref={profile}
                  className={
                    showProfile
                      ? "users dropdown-menu dropdown-menu-right show modalPosition"
                      : "users dropdown-menu dropdown-menu-right"
                  }
                  data-popper-placement="bottom-end"
                >
                  <div className="user-header">
                    <div className="avatar avatar-sm">
                      {profiledata.image ? <img src={imageUrl + "/User/" + profiledata.image} alt="User Image"
                        className="avatar-img rounded-circle" /> : null}
                    </div>
                    <div className="user-text">
                      <h6>{profiledata.firstname} {profiledata.lastname}</h6>
                      <p className="text-muted text mb-0">

                     

                        Level 3</p>
                    </div>
                  </div>
                  <Link
                    className="dropdown-item "
                    to="/teacher-dashboard"
                  >
                    <Home size={16} color={"#FF875A"} className="headerIcon mx-2" />{"  "} Dashboard </Link>
 
                  <Link className="dropdown-item " onClick={handleLogout}>
                    <LogOut
                      size={16}
                      color={"#FF875A"}
                      className="headerIcon mx-2"
                    />{" "}
                    Logout
                  </Link>
                </div>
              </li>
              
            </ul>
          </div>
        </nav>
        <div
          className={mobileMenu ? "sidebar-overlay opened" : "sidebar-overlay"}
        ></div>
      </div>
    </header>
  );
}
