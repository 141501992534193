import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";




import AddCourse from "./components/pages/course/addCourse";
import CourseDetails from "./components/pages/course/courseDetails";
import CourseDetails1 from "./components/pages/course/courseDetails1";
import CourseDetails2 from "./components/pages/course/courseDetails2";
import CourseGrid from "./components/pages/course/courseGrid";
import CourseLesson from "./components/pages/course/courseLesson";
import ComingSoon from "./components/pages/error/comingSoon";
import Error404 from "./components/pages/error/error404";
import Error500 from "./components/pages/error/error500";
import UnderConstruction from "./components/pages/error/underConstruction";

import Login from "./components/pages/login";

import PrivacyPolicy from "./components/pages/policy";

import StudentReport from "./components/student/depositDashboard/studentReport";
import TermsCondition from "./components/pages/termsCondition";


//harish
// import { Home } from "./components/home";
import { Dashboard } from "./components/instructor/dashboard";
import InstructorCourse from "./components/instructor/course";
import InstructorReviews from "./components/instructor/reviews";
import TeacherReports from "./components/instructor/teacherReports";
import LearningPortal from "./components/instructor/LearningPortal";
import LearningPortalList from "./components/instructor/LearningPortalList";
import LearningPortalDetail from "./components/instructor/LearningPortalDetail";
import InstructorEarnings from "./components/instructor/earnings";
import InstructorStudentGrid from "./components/instructor/StudentList";
import StudentsGrid from "./components/student/grid";
import StudentsList from "./components/student/list";
import TeacherProfile from "./components/instructor/TeacherProfile/TeacherProfile";
import DepositInstructor from "./components/instructor/depositInstructor";
import NewPassword from "./components/instructor/TeacherProfile/ChangePassword";
import NewPassword1 from "./components/student/StudentProfile/ChangePassword";
import StudentDepositDashboard from "./components/student/depositDashboard";
import StudentProfile from "./components/student/StudentProfile";
import StudentInfo from "./components/instructor/StudentList/StudentInfo";
import UserToken from "./components/pages/LoginviaKennisnet/user-token";
import EscapeReports from "./components/instructor/teacherReports/escapereports";
const Approuter = () => {
  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/teacher-dashboard" element={<Dashboard />} />
                
        <Route path="/instructor-course" element={<InstructorCourse />} />

        {/* Pages */}
        
        <Route path="/user-token" element={<UserToken />} />
        
        <Route path="/add-course" element={<AddCourse />} />
        <Route path="/teacher-profile" element={<TeacherProfile />} />
        <Route path="/course-grid" element={<CourseGrid />} />
        <Route path="/course-details/:id" element={<CourseDetails />} />
        <Route path="/course-details1" element={<CourseDetails1 />} />
        <Route path="/course-details2" element={<CourseDetails2 />} />
        <Route path="/course-lesson" element={<CourseLesson />} />
        <Route path="/come-soon" element={<ComingSoon />} />
        <Route path="/error-404" element={<Error404 />} />
        <Route path="/error-500" element={<Error500 />} />
        <Route path="/under-construction" element={<UnderConstruction />} />
        
        <Route path="/login" element={<Login />} />
        <Route path="/new-password" element={<NewPassword />} />
        <Route path="/change-password" element={<NewPassword1 />} />
        
        <Route path="/term-condition" element={<TermsCondition />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />

        {/* Instructor Routes */}
        <Route path="/teacher-dashboard" element={<Dashboard />} />     
        
        <Route path="/instructor-course" element={<InstructorCourse />} />
        
        <Route path="/instructor-reviews/:id" element={<InstructorReviews />} />
        <Route path="/instructor-earnings" element={<InstructorEarnings />} />
        <Route path="/teacher-reports" element={<TeacherReports />} />  
        <Route path="/teacher-escapereports" element={<EscapeReports />} />  
           
        <Route path="/learning-portal" element={<LearningPortal />} /> 
        <Route path="/learning-portal-list/:id" element={<LearningPortalList />} />
        <Route path="/learning-portal-detail/:id" element={<LearningPortalDetail />} /> 
        <Route
          path="/student-list"
          element={<InstructorStudentGrid />}
        />  
         <Route path="/student-info/:id" element={<StudentInfo />} />    
       <Route path="/deposit-instructor" element={<DepositInstructor />} />  
        <Route path="/students-grid" element={<StudentsGrid />} />        
        <Route path="/students-list" element={<StudentsList />} />
        <Route
          path="/student-dashboard"
          element={<StudentDepositDashboard />}
        />
        <Route
          path="/student-report"
          element={<StudentReport />}
        />
        
        <Route
          path="/student-profile"
          element={<StudentProfile />}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default Approuter;
