import clientAuth from "../clientAuth";


export default {


    all_module_with_task: () => clientAuth.get('teacher/all_module_with_task'),
    taskupdate: (userdata) => clientAuth.post('teacher/taskupdate', { json: userdata }),
    worst_played_student: (userdata) => clientAuth.post('teacher/worst/played/student', { json: userdata }),
    // worst_played_student: () => clientAuth.get('teacher/worst/played/student'),
    notes: (userid, lessonid, userdata) => clientAuth.post(`teacher/lesson/notes/${userid}/${lessonid}`, { json: userdata }),
    shownotes: (userid, lessonid) => clientAuth.get(`teacher/lesson/show/notes/${userid}/${lessonid}`),


};