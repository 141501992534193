import React, { useState, useEffect } from "react";
import {

  PieChart,
  Power,
  User,
  Lock

} from "react-feather";
import {

  logo,

} from "../../imagepath";
import { Link, useNavigate } from "react-router-dom";
import StickyBox from "react-sticky-box";

import UserService from "../../../Api/Services/UserService";
// eslint-disable-next-line react/prop-types
export default function InstructorSidebar({ activeMenu }) {
  const navigate = useNavigate();
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    if (localStorage.getItem('usertoken')) {
      var teacherdata = JSON.parse(localStorage.getItem("user"));
      setFirstname(teacherdata.firstname)
      setLastname(teacherdata.lastname)
    }
  }, [])
  const handleSidebar = () => {

    setIsOpen(prevCheck => !prevCheck);
  }
  const handleLogout = async () => {

    try {



      const data = await UserService.user_logout().json();
      if (data.status === true) {
        localStorage.removeItem("usertoken");
        localStorage.removeItem("user");
        navigate('/');
      }




    } catch (error) {
      console.log(error)
    }
  }
  return (
    <div className={isOpen ? "left-sidebar open" : "left-sidebar"}>
      <div className="theiaStickySidebar p-0">


        <StickyBox offsetTop={0} offsetBottom={0}>

          <div className="sideStickyBar">
            <div className="settings-widget dash-profile d-none">
              <div className="settings-menu p-0">
                <div className="profile-group p-5">

                  <div className="profile-name text-center">
                    <h4>
                      <Link to="#">{firstname} {lastname}</Link>
                    </h4>
                    <p className="mb-0">Teacher</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="logo-block">
              <Link  className="navbar-brand logo">
                <img src={logo} className="img-fluid" alt="Logo" />
              </Link>
              <div className="toggle-arrow" onClick={handleSidebar}>
                <Link to="#" >
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.5005 16.6L7.06719 11.1667C6.42552 10.525 6.42552 9.47503 7.06719 8.83336L12.5005 3.40002" stroke="#FD4A36" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </Link>
              </div>

            </div>
            <div className="settings-widget account-settings feather-icon border-0">
              <div className="settings-menu p-0 border-0">

                <ul>






                  <li className="nav-item ">
                    <Link
                      to="/teacher-dashboard"
                      className={
                        activeMenu === "Dashboard" ? "nav-item active" : "nav-item"
                      }
                    >
                      <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18.0402 4.82006L12.2802 0.79006C10.7102 -0.30994 8.30023 -0.24994 6.79023 0.92006L1.78023 4.83006C0.780234 5.61006 -0.00976562 7.21006 -0.00976562 8.47006V15.3701C-0.00976562 17.9201 2.06023 20.0001 4.61023 20.0001H15.3902C17.9402 20.0001 20.0102 17.9301 20.0102 15.3801V8.60006C20.0102 7.25006 19.1402 5.59006 18.0402 4.82006ZM10.7502 16.0001C10.7502 16.4101 10.4102 16.7501 10.0002 16.7501C9.59023 16.7501 9.25023 16.4101 9.25023 16.0001V13.0001C9.25023 12.5901 9.59023 12.2501 10.0002 12.2501C10.4102 12.2501 10.7502 12.5901 10.7502 13.0001V16.0001Z" fill="currentcolor" />
                      </svg> Dashboard
                    </Link>
                  </li>


                  <li className="nav-item d-none">
                    <Link
                      to="/instructor-earnings"
                      className={
                        activeMenu === "StudentGrid" ? "nav-item active" : "nav-item"
                      }
                    >
                      <PieChart size={20} />   Learning
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/student-list"
                      className={
                        activeMenu === "StudentGrid" ? "nav-item active" : "nav-item"
                      }
                    >
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.16055 10.87C9.06055 10.86 8.94055 10.86 8.83055 10.87C6.45055 10.79 4.56055 8.84 4.56055 6.44C4.56055 3.99 6.54055 2 9.00055 2C11.4505 2 13.4405 3.99 13.4405 6.44C13.4305 8.84 11.5405 10.79 9.16055 10.87Z" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M16.4093 4C18.3493 4 19.9093 5.57 19.9093 7.5C19.9093 9.39 18.4093 10.93 16.5393 11C16.4593 10.99 16.3693 10.99 16.2793 11" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M4.1607 14.56C1.7407 16.18 1.7407 18.82 4.1607 20.43C6.9107 22.27 11.4207 22.27 14.1707 20.43C16.5907 18.81 16.5907 16.17 14.1707 14.56C11.4307 12.73 6.9207 12.73 4.1607 14.56Z" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M18.3398 20C19.0598 19.85 19.7398 19.56 20.2998 19.13C21.8598 17.96 21.8598 16.03 20.2998 14.86C19.7498 14.44 19.0798 14.16 18.3698 14" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg> Mijn leerlingen
                    </Link>
                  </li>
                  <li className="nav-item mb-0">
                    <Link
                      to="/teacher-reports"
                      className={
                        activeMenu === "Reports" ? "nav-item active" : "nav-item"
                      }
                    >

                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M22 10V15C22 20 20 22 15 22H9C4 22 2 20 2 15V9C2 4 4 2 9 2H14" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M22 10H18C15 10 14 9 14 6V2L22 10Z" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M7 13H13" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M7 17H11" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg> Leerling resultaten
                    </Link>
                    <ul>
                      <li className="nav-item">
                    <Link
                      to="/teacher-escapereports"
                      className={
                        activeMenu === "EsReports" ? "nav-item active" : "nav-item"
                      }
                    >

                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M22 10V15C22 20 20 22 15 22H9C4 22 2 20 2 15V9C2 4 4 2 9 2H14" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M22 10H18C15 10 14 9 14 6V2L22 10Z" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M7 13H13" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M7 17H11" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg> OntSNAP de wiskunde
                    </Link>
                  </li>
                    </ul>
                  </li>
                  
                  <li className="nav-item">
                    <Link
                      to="/learning-portal"
                      className={
                        activeMenu === "Learning" ? "nav-item active" : "nav-item"
                      }
                    >



                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22H15C20 22 22 20 22 15Z" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M2.51953 7.10999H21.4795" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M8.51953 2.10999V6.96999" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M15.4805 2.10999V6.51999" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M9.75 14.45V13.25C9.75 11.71 10.84 11.08 12.17 11.85L13.21 12.45L14.25 13.05C15.58 13.82 15.58 15.08 14.25 15.85L13.21 16.45L12.17 17.05C10.84 17.82 9.75 17.19 9.75 15.65V14.45V14.45Z" stroke="currentcolor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>

                      Ondersteuning
                    </Link>
                  </li>
                  <li className="nav-item d-none">
                    <Link
                      to="/new-password"
                      className={
                        activeMenu === "NewPassword" ? "nav-item active" : "nav-item"
                      }
                    >
                      <Lock size={20} /> Change Password
                    </Link>
                  </li>
                  <li className="nav-item d-none">
                    <Link
                      to="/teacher-profile"
                      className={
                        activeMenu === "Profile" ? "nav-item active" : "nav-item"
                      }
                    >
                      <User size={20} />   Your Profile
                    </Link>
                  </li>
                </ul>

                <ul className="d-none">
                  <li className="nav-item">
                    <Link className="nav-link" onClick={handleLogout}>
                      <Power size={20} /> Sign Out
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>


        </StickyBox>
      </div>
    </div>
  );
}
