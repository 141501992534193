import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../footer";
import {


  Icon1,


} from "../../imagepath";
import Placeholder from '../../../assets/img/placeholder-image.png';
import { InstructorHeader } from "../header";
import InstructorSidebar from "../sidebar";
import LearningService from "../../../Api/Services/LearningService";
export default function InstructorEarnings() {
  const navigate = useNavigate();
  const [learningData, setLearningData] = useState([]);
  var imageUrl = process.env.REACT_APP_URL;
  useEffect(() => {
    if (localStorage.getItem('usertoken')) {
      var teacherdata = JSON.parse(localStorage.getItem("user"));
      if (teacherdata.roles[0].name === "Teacher") {
        learningmodule();
      }
      else {
        navigate('/')
      }

    } else {
      navigate('/login')
    }
  }, [navigate])
  const learningmodule = async () => {
    try {

      let responseData = await LearningService.all_module_with_task().json()

      if (responseData.status === true) {
        setLearningData(responseData.Module)

      }

    } catch (error) {
      console.log(error)
    }



  }
  return (
    <div className="main-wrapper">
      <InstructorHeader activeMenu={"Earnings"} />
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            {/* Sidebar */}
            <InstructorSidebar activeMenu={"Earnings"} />
            {/* Sidebar */}

            {/* Instruction Dashboard */}
            <div className="col-xl-10 col-lg-9 col-md-12">
              <div className="row">
                <div className="col-md-12">
                  <div className="settings-widget">
                    <div className="settings-inner-blk p-0">

                      <div className="comman-space pb-0">

                        <div className="settings-tickets-blk course-instruct-blk table-responsive">

                          <table className="table table-nowrap mb-2">
                            <thead>
                              <tr>
                                <th>COURSES</th>

                                <th className="text-end">STATUS</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                learningData.map((element) => {
                                  return (
                                    <>
                                      <tr>
                                        <td>
                                          <div className="sell-table-group d-flex align-items-center">
                                            <div className="sell-group-img">
                                              <Link to={`/course-details/${element.id}`}>
                                                <img
                                                  src={element.image ? imageUrl + "/Module/" + element.image : Placeholder}
                                                  className="img-fluid"
                                                  height={100}
                                                  width={100}
                                                  alt=""
                                                />
                                              </Link>
                                            </div>
                                            <div className="sell-tabel-info">
                                              <p>
                                                <Link to={`/course-details/${element.id}`}>
                                                  {element.name}
                                                </Link>
                                              </p>
                                              <div className="course-info d-flex align-items-center border-bottom-0 pb-0">
                                                <div className="rating-img d-flex align-items-center">
                                                  <img src={Icon1} alt="" />
                                                  <p>{element.total_lessons}+ Lesson</p>
                                                </div>

                                              </div>
                                            </div>
                                          </div>
                                        </td>

                                        <td className="text-end">
                                          <span className={element.status === "Pending" ? " badge info-medium" : "badge info-low"}>{element.status === "Pending" ? "Mark As Done" : "Completed"}</span>
                                        </td>
                                      </tr>
                                    </>
                                  )
                                })
                              }


                            </tbody>
                          </table>
                          {/* Referred Users */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Instruction Dashboard */}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
