
import { Home, LogOut } from "react-feather";
import React from "react";
import { useRef } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import { User11} from "../../../imagepath";


const HeadModule = () => {

  // const [showWish, setShowWish] = useState(false);
  // const [showNoti, setShowNoti] = useState(false);

  const [showProfile, setShowProfile] = useState(false);

  // Cart Click
  // const cartClick = (e) => {
  //   e.preventDefault();
  //   // if (showWish) {
  //   //   setShowWish(false);
  //   // }
  //   setShowCart(!showCart);
  //   console.log(showCart);
  // };

  // const wishClick = (e) => {
  //   e.preventDefault();
  //   // if (showCart) {
  //   //   setShowCart(false);
  //   // }
  //   setShowWish(!showWish);
  // };

  // const notiClick = (e) => {
  //   e.preventDefault();
  //   // if (showCart) {
  //   //   setShowCart(false);
  //   // }
  //   setShowNoti(!showNoti);
  // };

  const profileClick = (e) => {
    e.preventDefault();
    setShowProfile(!showProfile);
  };

  // Refs
 

  const profile = useRef();
  useOnClickOutside(profile, () => setShowProfile(false));

  return (
   

    <>
      <ul className="nav header-navbar-rht">


 

  <li className="nav-item user-nav">
  <Link
                  to="#"
                  className={
                    showProfile ? "dropdown-toggle show" : "dropdown-toggle"
                  }
                  data-bs-toggle="dropdown"
                  onClick={profileClick}
                >
      <span className="user-img">
        <img src={User11} alt="" />
        <span className="status online" />
      </span>
    </Link> 
    <div
                  ref={profile}
                  className={
                    showProfile
                      ? "users dropdown-menu dropdown-menu-right show modalPosition"
                      : "users dropdown-menu dropdown-menu-right"
                  }
                  data-popper-placement="bottom-end"
                >
      <div className="user-header">
        <div className="avatar avatar-sm">
          <img
            src={User11}
            alt="User Image"
            className="avatar-img rounded-circle"
          />
        </div>
        <div className="user-text">
          <h6>Rolands R</h6>
          <p className="text-muted mb-0">Student</p>
        </div>
      </div>
      <Link
                    className="dropdown-item text"
                    to="/teacher-dashboard"
                  >
                    <Home size={14} color={"#FF875A"} className="headerIcon" />{" "}
                    Dashboard
                  </Link>
     
      
                  <Link className="dropdown-item text" to="/">
                    <LogOut
                      size={14}
                      color={"#FF875A"}
                      className="headerIcon"
                    />{" "}
                    Logout
                  </Link>
    </div>
  </li>
</ul>

    </>
  );
};

export default HeadModule;
