import React, { useEffect, useState, useRef } from 'react';
import { InstructorHeader } from "../header";
import Footer from "../../footer";
import { useNavigate, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import InstructorSidebar from "../sidebar";
import SidebarTop from "../sidebartop";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ReactPlayer from "react-player";
import Loader from "../../Loader/Loader";
import LearningService from '../../../Api/Services/LearningService';

export default function LearningPortalDetail() {

  const navigate = useNavigate();
  const getDetails = useLocation();
  const playerRef = useRef(null)
  const propsData = getDetails.state;
  const [lessonData, setLessonData] = useState([]);
  const [lessonModuleID, setLessonModuleID] = useState([]);
  const [NewData, setNewData] = useState([]);
  const [NewTitle, setNewTitle] = useState([]);
  const [NewDescription, setNewDescription] = useState([]);
  const [NewNotes, setNewNotes] = useState([]);
  const [loader, setLoader] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [nextIndex, setNextIndex] = useState();

  var userId = JSON.parse(localStorage.getItem('user'))
  useEffect(() => {
    if (localStorage.getItem('usertoken')) {
      showNotes()
      // setNotestitle(responseData.data.title)
      // setDescription(responseData.data.description)

      var newtext = propsData.map((element) => { return element.title })

      setNewTitle(newtext)
      var newurl = propsData.map((element) => { return element.video_url })

      setNewData(newurl)
      var newdescription = propsData.map((element) => { return element.description })
      setNewDescription(newdescription)
      // var newnotes = propsData.map((element) => { return element.notes })

      // setNewNotes(newnotes)
      setLessonData(propsData)
      setLessonModuleID(propsData[0].module_id);
      setLoader(false)


    }
    else {
      navigate('/login')
    }
  }, [])
  const handlePrevClick = () => {

    if (currentIndex > 0) {

      setCurrentIndex(currentIndex - 1);
      setNextIndex(1)
    }
    else {

      setCurrentIndex(0);

    }
  }
  const handleNextClick = () => {
    var newtext = lessonData.map((element) => { return element.title })
    var newurl = lessonData.map((element) => { return element.video_url })

    setNewData(newurl)
    setNewTitle(newtext)
    if (currentIndex < newtext.length - 1) {
      setCurrentIndex(currentIndex + 1);

    }
    else {
      setNextIndex(0)
    }
  }
  const showNotes = async () => {
    let responseData = await LearningService.shownotes(userId.id, propsData[0].module_id).json()
    setNewNotes(responseData.data)

  }
  const handlenoties = async (event) => {
    event.preventDefault();

    var userData = {
      "title": event.target.title.value,
      "description": event.target.description.value
    }
    let responseData = await LearningService.notes(userId.id, propsData[0].module_id, userData).json()
    if (responseData.status === true) {
      showNotes()

    }


  }

  return (
    <div className="main-wrapper">
      <InstructorHeader activeMenu={"Learning"} />
      {/* Page Wrapper */}
      <div className="page-content p-0">
        <div className="container-fluid">
          <div className="row">
            <div className='col-md-12 p-0'>

              {/* Sidebar */}
              <InstructorSidebar activeMenu={"Learning"} />
              {/* Sidebar */}


              <div className='full-page-content'>
                <div className="title-bar">
                  <div className="page-title-left">
                    <h5 className="page-title">
                      <span className='back-arrow'>
                        <a onClick={() => navigate(`/learning-portal-list/${lessonModuleID}`)} style={{ cursor: "pointer" }}>
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 19L5 12L12 5" stroke="#121212" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M19 12H5" stroke="#121212" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                        </a>
                      </span>
                      {NewTitle[currentIndex]}
                    </h5>
                  </div>
                  <div className="page-title-right">

                    {/* SidebarTop */}
                    <SidebarTop />
                    {/* SidebarTop */}

                  </div>

                </div>
                <div className='row'>
                  {/* Instructor Dashboard */}
                  <div className="col-xl-12 col-lg-2 col-md-12">
                    {loader ? <Loader /> :
                      <div className="row">

                        {/* Instructor List */}
                        <div className="col-lg-12">
                          <div className="common-page-padding">
                            <div className='lesson-detail learning-portal-list'>
                              <div className='lesson-detail-topbar mb-4'>
                                <div className='left-content'>
                                  <div className='breadcrumb-list'>
                                    {/* <nav aria-label="breadcrumb" className="page-breadcrumb">
                                      <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a onClick={() => navigate(`/learning-portal-list/${lessonModuleID}`)} style={{cursor: "pointer"}}>Course</a></li>
                                        <li className="breadcrumb-item current" aria-current="page">Lesson</li>

                                      </ol>
                                    </nav> */}

                                  </div>
                                </div>
                                <div className='right-content'>
                                  <div className='prev-next-lesson'>
                                    <a to="" className={currentIndex === 0 ? "btn" : "btn active ms-3"} onClick={() => handlePrevClick()} >
                                      <i className="fas fa-angle-left me-2"></i>  Vorige les
                                    </a>
                                    <a to="" className={nextIndex === 0 ? "btn" : "btn active ms-3"} onClick={() => handleNextClick()}>
                                      Volgende les <i className="fas fa-angle-right ms-2"></i>
                                    </a>

                                  </div>


                                </div>

                              </div>

                              <div className='lesson-detail-video'>
                                <div className='video-btn'>
                                  <Link to="" >

                                    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M32.0007 5.3335C17.2807 5.3335 5.33398 17.2802 5.33398 32.0002C5.33398 46.7202 17.2807 58.6668 32.0007 58.6668C46.7207 58.6668 58.6673 46.7202 58.6673 32.0002C58.6673 17.2802 46.7207 5.3335 32.0007 5.3335ZM39.094 36.6135L35.6807 38.5868L32.2673 40.5602C27.8673 43.0935 24.2673 41.0135 24.2673 35.9468V32.0002V28.0535C24.2673 22.9602 27.8673 20.9068 32.2673 23.4402L35.6807 25.4135L39.094 27.3868C43.494 29.9202 43.494 34.0802 39.094 36.6135Z" fill="white" />
                                    </svg>
                                  </Link>
                                </div>
                                <ReactPlayer ref={playerRef} url={NewData[currentIndex]} controls={true} className='img-fluid rounded w-100 video-iframe' />

                              </div>
                              <div className='lesson-detail-content'>
                                <div className='lesson-detail-tabs'>

                                  <Tabs
                                    defaultActiveKey="Lesson"
                                    id="uncontrolled-tab-example"
                                    className="mb-3"
                                  >
                                    <Tab eventKey="Lesson" title="Les info">
                                      <p className='description' dangerouslySetInnerHTML={{ __html: NewDescription[currentIndex] }} >
                                      </p>

                                    </Tab>
                                    <Tab eventKey="Notes" title="Notities">
                                      <div className='notities-content'>
                                        <div className='notities-description'>
                                          {/* <p className='description' dangerouslySetInnerHTML={{ __html: NewNotes[currentIndex] }} >
                                          </p> */}
                                          {
                                            NewNotes.map((element) => {
                                              return (
                                                <>
                                                  Titel:<p className='description'>{element.title}
                                                  </p>
                                                  Notities:<p className='description' dangerouslySetInnerHTML={{ __html: element.description }} >
                                                  </p><hr/>
                                                </>
                                              )
                                            })
                                          }

                                        </div>
                                        <form onSubmit={handlenoties}>
                                          <div className="form-group text-start">
                                            <label className="form-control-label">Notities</label>
                                            <textarea
                                              type="text"
                                              name='description'
                                              className="form-control"

                                            />
                                          </div>
                                          <div className="form-group text-start">
                                            <label className="form-control-label">Titel </label>
                                            <input
                                              type="text"
                                              name='title'
                                              className="form-control"

                                            />
                                          </div>
                                          <div className="form-group text-start modal-footer">
                                            <button type="submit" className="btn btn-secondary " >Verzenden</button>&nbsp;
                                            <button type="reset" value="reset" className="btn btn-secondary btn-outline" >Sluit</button>
                                          </div>
                                        </form>
                                      </div>
                                    </Tab>

                                  </Tabs>


                                </div>

                              </div>
                            </div>

                          </div>
                        </div>



                      </div>
                    }

                  </div>
                  {/* Instructor Dashboard */}

                </div>


              </div>

            </div>



          </div>
        </div >
      </div >
      {/* Page Wrapper */}
      < Footer />
    </div >
  );
}
