import React from "react";
import { Link } from "react-router-dom";
import { logo } from "../../../imagepath";
import HeadModule from "../module";

// eslint-disable-next-line react/prop-types
const CourseHeader = () => {

  const openMobileMenu = () => {
    document.body.classList.add("menu-opened");
  };
  const hideMobileMenu = () => {
    document.body.classList.remove("menu-opened");
  };





  return (
    <>
      <header className="header header-page theme-header">
        <div className="header-fixed">
          <nav className="navbar navbar-expand-lg header-nav scroll-sticky">
            <div className="container header-border container-fluid">
              <div className="navbar-header">
                <Link id="mobile_btn" to="#" onClick={openMobileMenu}>
                  <span className="bar-icon">
                    <span />
                    <span />
                    <span />
                  </span>
                </Link>
                <Link to="/" className="navbar-brand logo">
                  <img src={logo} className="img-fluid" alt="Logo" />
                </Link>
              </div>
              <div className="main-menu-wrapper">
                <div className="menu-header">
                  <Link to="/" className="menu-logo">
                    <img src={logo} className="img-fluid" alt="Logo" />
                  </Link>
                  <Link
                    id="menu_close"
                    className="menu-close"
                    to="#"
                    onClick={hideMobileMenu}
                  >
                    <i className="fas fa-times"></i>
                  </Link>
                </div>
                <ul className="main-nav">
                  <li className="has-submenu">
                    <Link to="/">
                      Home 
                     
                    </Link>
                    
                  </li>
                  <li className="has-submenu">
                    <Link to="/teacher-dashboard">
                      Teacher
                    </Link>
                  
                  </li>
                 
                
                  <li className="login-link">
                    <Link to="/login" onClick={hideMobileMenu}>
                      Login / Signup
                    </Link>
                  </li>
                </ul>
              </div>

              <HeadModule />
            </div>
          </nav>
        </div>
      </header>
    </>
  );
};

export default CourseHeader;
