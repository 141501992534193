import React from "react";
import { Link } from "react-router-dom";


const Footer = () => {
  return (


    <footer className="footer">
      
      <div className="footer-top">
            <div className="container">
                <div className="row g-0">
                    <div className="col-lg-4 col-md-4 col-12">
                          <div className="footer-col-inner">
                              <div className="icon-list">
                                  <div className="icon-list-icon">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="800px" height="800px" viewBox="0 0 20 20" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M10 11.5C11.933 11.5 13.5 9.933 13.5 8C13.5 6.067 11.933 4.5 10 4.5C8.067 4.5 6.5 6.067 6.5 8C6.5 9.933 8.067 11.5 10 11.5ZM10 6.5C10.8284 6.5 11.5 7.17157 11.5 8C11.5 8.82843 10.8284 9.5 10 9.5C9.17157 9.5 8.5 8.82843 8.5 8C8.5 7.17157 9.17157 6.5 10 6.5Z" fill="#000000"></path><path fillRule="evenodd" clipRule="evenodd" d="M2.5 8.12313C2.5 12.3656 6.88183 19.5 10 19.5C13.1182 19.5 17.5 12.3656 17.5 8.12313C17.5 3.91715 14.1464 0.5 10 0.5C5.85362 0.5 2.5 3.91715 2.5 8.12313ZM15.5 8.12313C15.5 11.4027 11.7551 17.5 10 17.5C8.24487 17.5 4.5 11.4027 4.5 8.12313C4.5 5.0134 6.96668 2.5 10 2.5C13.0333 2.5 15.5 5.0134 15.5 8.12313Z" fill="#000000"></path></svg>
                                  </div>
                                  <div className="icon-list-text">Poortland 66,  1046BD Amsterdam</div>                               

                              </div>

                              <div className="icon-list">
                                  <div className="icon-list-icon">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="800px" height="800px" viewBox="0 0 20 20" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M6.25 3C6.25 2.86193 6.36193 2.75 6.5 2.75H13.5C13.6381 2.75 13.75 2.86193 13.75 3V15C13.75 15.1381 13.6381 15.25 13.5 15.25H6.5C6.36193 15.25 6.25 15.1381 6.25 15V3Z" fill="#000000"></path><path fillRule="evenodd" clipRule="evenodd" d="M14.5 0H5.5C4.39543 0 3.5 0.942558 3.5 2.10526V17.8947C3.5 19.0574 4.39543 20 5.5 20H14.5C15.6046 20 16.5 19.0574 16.5 17.8947V2.10526C16.5 0.942558 15.6046 0 14.5 0ZM5.5 18V2H14.5V18H5.5Z" fill="#000000"></path><path d="M10.5 16.5C10.5 16.7761 10.2761 17 10 17C9.72386 17 9.5 16.7761 9.5 16.5C9.5 16.2239 9.72386 16 10 16C10.2761 16 10.5 16.2239 10.5 16.5Z" fill="#000000"></path><path fillRule="evenodd" clipRule="evenodd" d="M9 16.5C9 15.9477 9.44772 15.5 10 15.5C10.5523 15.5 11 15.9477 11 16.5C11 17.0523 10.5523 17.5 10 17.5C9.44772 17.5 9 17.0523 9 16.5Z" fill="#000000"></path></svg>
                                  </div>
                                  <div className="icon-list-text">
                                    <Link to="tel:020-2050486">020-2050486</Link>
                                  </div>                               

                              </div>

                              <div className="icon-list">
                                  <div className="icon-list-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="800px" height="800px" viewBox="0 0 20 20" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M17 3.5H3C2.44772 3.5 2 3.94772 2 4.5V15.5C2 16.0523 2.44772 16.5 3 16.5H17C17.5523 16.5 18 16.0523 18 15.5V4.5C18 3.94772 17.5523 3.5 17 3.5ZM4 14.5V5.5H16V14.5H4Z" fill="#000000"></path><path fillRule="evenodd" clipRule="evenodd" d="M17.6484 5.26131L10.6034 11.2613C10.2279 11.5811 9.67524 11.5794 9.30177 11.2572L2.34679 5.25718C1.64501 4.65176 2.07317 3.5 3 3.5H17C17.93 3.5 18.3564 4.65833 17.6484 5.26131ZM5.69006 5.5L9.95917 9.18292L14.2835 5.5H5.69006Z" fill="#000000"></path></svg>
                                  </div>
                                  <div className="icon-list-text">
                                    <Link to="mailto:info@vronderwijs.nl">info@vronderwijs.nl</Link>                                    
                                  </div>                               

                              </div>
                          </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-12">
                      <div className="footer-col-inner text-md-center">
                        <ul>
                          <li>
                            <a href="https://cdn.cookiecode.nl/privacy/vronderwijs.nl/nl/pdf" >Privacybeleid</a>
                          </li>
                          <li>
                            <a href="https://cdn.cookiecode.nl/cookie/vronderwijs.nl/nl/pdf" >Cookieverklaring</a>
                          </li>
                          <li>
                          <a href="https://www.vronderwijs.nl/wp-content/uploads/2024/02/Algemene-Voorwaarden-Diensten-VRONDERWIJS-versie-1.1-14dec2023.pdf" >Algemene voorwaarden</a>
                          </li>
                        </ul>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-12">
                    <div className="footer-col-inner text-md-end">
                        <ul>
                            <li>
                              <a href="https://www.vronderwijs.nl/blog/">Blog</a>
                            </li>
                            <li>
                              <a href="https://www.vronderwijs.nl/over-vronderwijs/">Over VRonderwijs</a>
                            </li>
                            <li>
                            <a href="https://www.vronderwijs.nl/contact">Demo aanvragen</a>
                            </li>
                        </ul>
                        </div>
                    </div>
                </div>
            </div>
      </div>


      {/* Footer Bottom */}
      <div className="footer-bottom">
        <div className="footer-bottom-inner">
        <div className="container">
          
          <div className="copyright p-0">
            <div className="row">

              <div className="col-md-12 text-center">
                <div className="d-flex justify-content-between flex-wrap">
                  <div className="footer-left">
                    <p>Website ontwikkelt door <a href="https://www.virtuelehelden.nl">Virtuele Helden</a> © 2024</p>
                    
                  </div>
                  <div className="privacy-policy">
                    <ul>
                      <li>
                      Website Developed by <Link target="_blank" to="https://rigicglobalsolutions.com/">Rigic Global Solutions Pvt Ltd</Link> | All Rights Reserved.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /Copyright */}
        </div>
        </div>
        
      </div>
      {/* /Footer Bottom */}
    </footer>
  );
};

export default Footer;
