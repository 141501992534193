import React, { useState, useEffect } from "react";
import {
  Home,

  Power,
  User,
  Lock
} from "react-feather";
import { Link, useNavigate } from "react-router-dom";
import StickyBox from "react-sticky-box";

import UserService from "../../../Api/Services/UserService";
import StudentService from "../../../Api/Services/StudentService";
// eslint-disable-next-line react/prop-types
export default function StudentSidebar({ activeMenu }) {
  const navigate = useNavigate();
  const [profiledata, setProfiledata] = useState('');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');

  var imageUrl = process.env.REACT_APP_URL;
  useEffect(() => {
    if (localStorage.getItem('usertoken')) {
      var studentdata = JSON.parse(localStorage.getItem("user"));
      setFirstname(studentdata.firstname);
      setLastname(studentdata.lastname);

      handleStudentProfile();
    }
  }, [])
  const handleLogout = async () => {

    try {



      const data = await UserService.user_logout().json();
      if (data.status === true) {
        localStorage.removeItem("usertoken");
        localStorage.removeItem("user");
        navigate('/');
      }




    } catch (error) {
      console.log(error)
    }
  }

  const handleStudentProfile = async () => {
    try {
      let responseData = await StudentService.student_profile().json()
      if (responseData.status === true) {
        setProfiledata(responseData);
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className="col-xl-3 col-md-4 theiaStickySidebar">
      <StickyBox offsetTop={20} offsetBottom={20}>
        <div className="sideStickyBar">
          <div className="settings-widget dash-profile">
            <div className="settings-menu p-0">
              <div className="profile-group">
                <div className="profiles-img text-center mb-4">

                  {profiledata.image ? <img src={imageUrl + "/User/" + profiledata.image} alt='' className='img-fluid' /> : null}

                </div>
                <div className="profile-name text-center">
                  <h4>
                    <Link to="#">{firstname} {lastname}</Link>
                  </h4>
                  <p className="mb-0 mt-2">

                    <svg className="me-2" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8 13.3337V6.66699" stroke="#61677A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M12 13.3337V2.66699" stroke="#61677A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M4 13.3337V10.667" stroke="#61677A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>

                {(profiledata.levelname == undefined || profiledata.levelname == null || profiledata.levelname == "") ? "-" : profiledata.levelname.charAt(0).toUpperCase() + profiledata.levelname.slice(1)}</p>
                </div>
                <div className="profile-details">
                  <div className="profile-details-row mb-4">
                    <span>
                      Klas
                    </span>
                    <h6 className="mt-2">
                      {(profiledata.classroom == undefined || profiledata.classroom == null || profiledata.classroom == "") ? "-" : profiledata.classroom}
                    </h6>

                  </div>
                  <div className="profile-details-row mb-4">
                    <span>
                      Docent
                    </span>
                    <h6 className="mt-2">
                      {profiledata.teachername}
                    </h6>

                  </div>
                  <div className="profile-details-row mb-4">
                    <span>
                      Continuïteitsscore
                    </span>
                    <h6 className="mt-2">
                      {(profiledata.continuityscore == undefined || profiledata.continuityscore == null || profiledata.continuityscore == "") ? "-" : profiledata.continuityscore}
                    </h6>

                  </div>


                </div>
              </div>
            </div>
          </div>
          <div className="settings-widget account-settings feather-icon d-none">
            <div className="settings-menu">

              <ul>
                <li className="nav-item">
                  <Link
                    to="/student-dashboard"
                    className={
                      activeMenu === "Dashboard" ? "nav-item active" : "nav-item"
                    }
                  >
                    <Home size={20} /> Dashboard
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/change-password"
                    className={
                      activeMenu === "NewPassword" ? "nav-item active" : "nav-item"
                    }
                  >
                    <Lock size={20} /> Change Password
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/student-profile"
                    className={
                      activeMenu === "Student" ? "nav-item active" : "nav-item"
                    }
                  >
                    <User size={20} />   Your Profile
                  </Link>
                </li>
              </ul>

              <ul>
                <li className="nav-item">
                  <Link className="nav-link" onClick={handleLogout}>
                    <Power size={20} /> Sign Out
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </StickyBox>
    </div>
  );
}
