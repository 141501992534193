import React, { useState, useEffect } from 'react';
import TeacherService from '../../../Api/Services/TeacherService';
import InstructorSidebar from "../../instructor/sidebar";
import { InstructorHeader } from "../../instructor/header";
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import Footer from "../../footer";
import SidebarTop from "../sidebartop";
const NewPassword = () => {
    const [Success, setSuccess] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [eye1, seteye1] = useState(true)
    const [eye2, seteye2] = useState(true)
    const [eye3, seteye3] = useState(true)
    const [Error, setError] = useState("")
    const navigate = useNavigate();
    const onEyeClick1 = () => {
        seteye1(!eye1)
    }
    const onEyeClick2 = () => {
        seteye2(!eye2)
    }
    const onEyeClick3 = () => {
        seteye3(!eye3)
    }

    useEffect(() => {
        if (localStorage.getItem('usertoken')) {

            console.log("")


        } else {
            navigate('/login')
        }
    }, [navigate])


    const addStudent = async (event) => {

        event.preventDefault();

        if (event.target.current_password.value === "") {
            setError("Het huidige wachtwoordveld is vereist.")
        }
        else if (event.target.new_password.value === "") {
            setError("Het nieuwe wachtwoordveld is vereist.")
        }
        else if (event.target.confirm_password.value === "") {
            setError("Het veld Bevestig wachtwoord is vereist.")
        }
        else if (event.target.new_password.value != event.target.confirm_password.value) {
            setError("Het veld Bevestig wachtwoord moet overeenkomen met het nieuwe wachtwoord.")

        }
        else {
            try {
                var userData = {

                    current_password: event.target.current_password.value,
                    new_password: event.target.new_password.value,
                    confirm_password: event.target.confirm_password.value

                }

                let responseData = await TeacherService.changepassword(userData).json()
                if (responseData.status === true) {

                    setSuccess(true)
                    setModalMessage(responseData.message)
                    setError("");

                }
                else {
                    setError("Huidig ​​wachtwoord komt niet overeen!");
                }
            } catch (error) {

                if (error.name === 'HTTPError') {
                    const errorJson = await error.response.json();

                    setError(errorJson.message.substr(0, errorJson.message.lastIndexOf(".")))
                }
            }

        }

    };
    return (
        <>
            <div className="main-wrapper">
                <InstructorHeader activeMenu={"NewPassword"} />
                <div className="page-content instructor-page-content p-0">
                    <div className="container-fluid">
                        <div className="row">
                            <div className='col-md-12 p-0'>

                                {/* Sidebar */}
                                <InstructorSidebar activeMenu={"NewPassword"} />

                                <div className='full-page-content'>
                                    <div className="title-bar">
                                        <div className="page-title-left">
                                            <h4 className="page-title mb-0">
                                                Wijzig wachtwoord
                                            </h4>
                                        </div>
                                        <div className="page-title-right">

                                            {/* SidebarTop */}
                                            <SidebarTop />
                                            {/* SidebarTop */}

                                        </div>

                                    </div>
                                    <div className="col-xl-12 col-lg-12">
                                        {/* //col-md-12 */}

                                        <div className='row justify-content-center'>
                                            <div className='col-xl-8 col-md-10'>
                                                <div className="settings-widget profile-details p-0 changepassword  mt-lg-5 mt-4">
                                                    <div className='settings-menu p-0'>
                                                        {/* <div className="profile-heading">
                                            <h4 className='mb-0'>Change Password</h4>
                                        </div> */}
                                                        <div className="checkout-form personal-address">

                                                            <form onSubmit={addStudent}>
                                                                <div className="row">

                                                                    <div className="form-group text-start">
                                                                        <label className="form-control-label">Huidig wachtwoord</label>
                                                                        <div className="pass-group" id="passwordInputs">
                                                                            <input
                                                                                type={eye1 ? "password" : "text"}
                                                                                name='current_password'
                                                                                className="form-control"


                                                                            />
                                                                            <span onClick={onEyeClick1} className={`fa toggle-password feather-eye" ${eye1 ? "fa-eye" : "fa-eye-slash"}`} />
                                                                            <span className="pass-checked">
                                                                                <i className="feather-check" />
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group text-start">
                                                                        <label className="form-control-label">Nieuw wachtwoord </label>
                                                                        <div className="pass-group" id="passwordInputs">
                                                                            <input
                                                                                type={eye2 ? "password" : "text"}
                                                                                name='new_password'
                                                                                className="form-control"


                                                                            />
                                                                            <span onClick={onEyeClick2} className={`fa toggle-password feather-eye" ${eye2 ? "fa-eye" : "fa-eye-slash"}`} />
                                                                            <span className="pass-checked">
                                                                                <i className="feather-check" />
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group text-start">
                                                                        <label className="form-control-label">Bevestig wachtwoord</label>
                                                                        <div className="pass-group" id="passwordInputs">
                                                                            <input
                                                                                type={eye3 ? "confirm_password" : "text"}
                                                                                name='confirm_password'
                                                                                className="form-control"


                                                                            />
                                                                            <span onClick={onEyeClick3} className={`fa toggle-password feather-eye" ${eye3 ? "fa-eye" : "fa-eye-slash"}`} />
                                                                            <span className="pass-checked">
                                                                                <i className="feather-check" />
                                                                            </span>
                                                                        </div>
                                                                    </div>


                                                                    {Error ? <p className=" text-center items-center justify-between rounded bg-warning py-1 text-sm text-white">{Error}</p> : ''}
                                                                    <div className="update-profile">
                                                                        <button type="submit" className="btn btn-primary" >Verzend</button>

                                                                    </div>
                                                                </div>
                                                            </form>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>

                            </div>



                        </div></div>
                </div>
            </div>
            <Modal show={Success}>
                <Modal.Header>
                    <Modal.Title>Save</Modal.Title>
                </Modal.Header>

                <Modal.Body>{modalMessage}</Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {


                            setSuccess(false)
                            navigate('/teacher-dashboard')
                        }}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Footer />
        </>
    );
};

export default NewPassword;
