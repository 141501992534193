import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import StudentHeader from "../header";
import Footer from "../../footer";
import GameService from '../../../Api/Services/GameService';
import InstructorSidebar from "../sidebar";
import Loader from "../../Loader/Loader";
export default function StudentDepositDashboard() {

  const [isLoading, setIsLoading] = useState(true);
  const [Zerotestdata, setZerotestdata] = useState([]);
  const [DownloadLoader, setDownloadLoader] = useState(false);
  const [Frenchlanguagedata, setFrenchlanguagedata] = useState([]);
  const [Escapedata, setEscapedata] = useState([]);
  const [Arithmeticdata, setArithmeticdata] = useState([]);
  const [ModalOpen, setModelOpen] = useState(false);
  const [SeeDetails, setSeeDetails] = useState([]);
  const [Error, setError] = useState("")
  const [SubjectError, setSubjectError] = useState("")
  const [SubjectName, setSubjectName] = useState("")
  const [subjectData, setSubjectData] = useState([]);
  const [subjectID, setSubjectID] = useState("");
  const navigate = useNavigate();
  var imageUrl = process.env.REACT_APP_URL;

  var studentdata = JSON.parse(localStorage.getItem("user"));
  useEffect(() => {
    if (localStorage.getItem('usertoken')) {




      SubjectList();
      handlegamescorelist();


    } else {
      navigate('/login')
    }
  }, [navigate])



  const SubjectList = async () => {
    try {
      let responseData = await GameService.index().json();
      var getclass = responseData.filter(function (item) {

        return item.id == 1;
      });
      setSubjectName(getclass[0].name)
      if (getclass[0].message === "You are not authorized to this game") {
        setSubjectError(getclass[0].message)
      }
      var removeclass = responseData.filter(function (item) {

        return item.name == "ontSNAP de wiskunde" ? "" : item;
      });


      handlegamescorelist(getclass[0].id)
      setSubjectID(getclass[0].id)
      setSubjectData(removeclass);

    } catch (error) {
      console.log(error)
    }
  }

  const handlegamescorelist = async (id) => {

    setIsLoading(true)
    try {


      
      if (id === 2) {

        try {

          let responseData = await GameService.gamelist(2).json();

          setFrenchlanguagedata(responseData.data)
          setZerotestdata([])
          setEscapedata([])
          setArithmeticdata([])
          setIsLoading(false)

        } catch (error) {
          console.log(error)
        }


      }
      else if (id === 1) {

        try {
          let responseData = await GameService.gamelist(1).json();
          setArithmeticdata(responseData.data)
          setZerotestdata([])
          setFrenchlanguagedata([])
          setEscapedata([])
          setIsLoading(false)

        } catch (error) {
          console.log(error)
        }



      }
      else if (id === '4') {

        try {
          let responseData = await GameService.zero_test().json();
          setIsLoading(false)
          setFrenchlanguagedata([])
          setEscapedata([])
          if (responseData.data.length === 0) {
            setError("Data Not Found");
          }
          setZerotestdata(responseData.data)
          setArithmeticdata([])

        } catch (error) {

          console.log(error)

        }
      }
      else if (id === 3) {

        try {
          let responseData = await GameService.escapelist().json();
          setIsLoading(false)
          setFrenchlanguagedata([])
          setZerotestdata([])
          if (responseData.data.length === 0) {
            setError("Data Not Found");
          }
          setEscapedata(responseData.data)
          setArithmeticdata([])

        } catch (error) {

          console.log(error)

        }
      }
      else {
        console.log("blank")
      }
    } catch (error) {
      console.log(error)
    }
  }

  const DownaloadCertificate = async (data) => {

    // 


    try {
      setDownloadLoader(true)
      let responseData = await GameService.certificate(data.level_id, subjectID).blob();
      setDownloadLoader(false)
      const downloadUrl = URL.createObjectURL(responseData);
      const a = document.createElement('a');
      a.href = downloadUrl;
      a.setAttribute(
        'download',
        `${studentdata.firstname + studentdata.lastname + "(" + (SubjectName) + ")" + "(" + (data.level_name) + ")"}.pdf`

      );
      document.body.appendChild(a);
      a.click();
      a.parentNode.removeChild(a);
    } catch (error) {
      console.log(error)
    }
  }

  const handlesubjectlist = (id) => {
    setSubjectName(id.name)
    if (id.message === "You are not authorized to this game") {
      setSubjectError(id.message)
    }
    else {
      setSubjectError("")
      setSubjectID(id.id);
      handlegamescorelist(id.id);
    }


  }
  const handleModal = async (level_id) => {

    if (level_id === "escape") {


      try {
        let responseData = await GameService.escapeseedetails().json();
        setSeeDetails(responseData.levels)
        setModelOpen(true)
      } catch (error) {
        console.log(error)
      }
    }
    else {

      let gameId = subjectID

      try {
        let responseData = await GameService.gamedetail(gameId).json();
        setSeeDetails(responseData)
        setModelOpen(true)
      } catch (error) {
        console.log(error)
      }
    }


  }
  const handleBulkCertificate = async () => {
    setDownloadLoader(true);
    setError(null);


    const apiUrl = `https://lmsapi.rigicgspl.com/api/student/user/download/bulk/certificates/${subjectID}`; // Replace with your API URL
    const authToken = JSON.parse(localStorage.getItem('usertoken'));


    try {
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/zip',
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const blob = await response.blob();
      const downloadUrl = URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = downloadUrl;
      a.setAttribute('download', `${studentdata.firstname + studentdata.lastname + "(" + (SubjectName) + ")"}.zip`); // Replace with desired file name
      document.body.appendChild(a);
      a.click();
      a.parentNode.removeChild(a);

      // Clean up the object URL
      URL.revokeObjectURL(downloadUrl);
    } catch (error) {

      setError('Failed to download file. Please try again.');
    } finally {
      setDownloadLoader(false);
    }
  };

  // const handleBulkCertificate = async () => {


  //   try {
  //     setDownloadLoader(true);
  //     let responseData = await GameService.downloadbulk_certificates(subjectID).blob();
  //     setDownloadLoader(false);
  //     const downloadUrl = URL.createObjectURL(responseData);
  //     const a = document.createElement('a');
  //     a.href = downloadUrl;
  //     a.setAttribute(
  //       'download',

  //       `${namecertificate}.zip`
  //     );
  //     document.body.appendChild(a);
  //     a.click();
  //     a.parentNode.removeChild(a);

  //   } catch (error) {
  //     console.log(error)
  //   }
  // }
  return (
    <div className="main-wrapper">
      <StudentHeader activeMenu={"Dashboard"} />
      <div className="page-content instructor-page-content pt-4 mt-1">
        <div className="container">
          <div className="row">
            {/* Sidebar */}
            <InstructorSidebar activeMenu={"Dashboard"} />
            {/* Sidebar */}

            {/* Instructor Dashboard */}
            <div className="col-xl-9 col-md-8 p-0">
              {isLoading ? <Loader /> :
                <div className="row">
                  <div className="common-full-content">
                    <div className='filter-tabs mt-2'>
                      {DownloadLoader ? <div className='loader-overlay'><Loader /></div> : ''}
                      <ul>
                        {subjectData.map((data) => (

                          <li key={data.id} onClick={() => setSubjectID(data.id)}>
                            <Link className={subjectID == data.id ? "active" : "inactive"} to=""
                              // onClick={() => handlesubjectlist(data.id)}
                              onClick={() => handlesubjectlist(data)}
                            >
                              <img className="img-drop-area" height={data.icon ? 15 : 0} width={data.icon ? 15 : 0} src={imageUrl + "/Subject/" + data.icon} alt='' />
                              &nbsp;
                              {data.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className='row mt-4 pt-3'></div>

                  </div>
                  {
                    SubjectError ?

                      <div key={"scorelist"} className='col-xl-12 col-lg-12 col-md-6 text-center justi-content-center '>
                        <div className='col-xl-10 m-auto mt-5 '>
                          <div className='student-box flex-fill bg-white '>
                            <div className='student-box-top  justify-content-between align-items-center'>
                              <h6 className='mb-0'>{SubjectError}</h6>

                            </div>
                          </div>
                        </div>
                      </div>

                      :
                      <>

                        {
                          Arithmeticdata.map((data) => {
                            return (
                              <>

                                <div key={"scorelist"} className='col-xl-4 col-lg-4 col-md-6 text-center '>
                                  <div className='student-box flex-fill bg-white '>

                                    <div className='student-box-top d-flex justify-content-between align-items-center mt-4'>
                                      <h6 className='mb-0'>{(data.level_name == undefined || data.level_name == null || data.level_name == "") ? "-" : data.level_name.charAt(0).toUpperCase() + data.level_name.slice(1)}</h6>
                                      <span className={data.status == "not yet" ? "lable not" : "lable"}>{(data.status == undefined || data.status == null || data.status == "") ? "-" : (data.status == "not yet" ? "Nog niet" : "Behaald")}</span>
                                    </div>
                                    <div className='student-box-middle'>
                                      <h5>{(data.final_score == undefined || data.final_score == null || data.final_score == "") ? "-" : data.final_score}</h5>
                                      <span>Eindresultaat</span>
                                    </div>

                                    <div className='student-box-bottom'>
                                      {
                                        data.status == "not yet" ?
                                          ""
                                          :
                                          <Link to="#" className='btn d-block outline' onClick={() => handleModal(data.level_id)}>
                                            Bekijk details
                                          </Link>
                                      }

                                      {
                                        data.is_certificate_download === "true" ?
                                          <Link to="#" className="btn d-block mt-3" onClick={() => DownaloadCertificate(data)}>
                                            <svg className='me-2' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M12 17C12.5304 17 13.0391 16.7893 13.4142 16.4142C13.7893 16.0391 14 15.5304 14 15C14 14.4696 13.7893 13.9609 13.4142 13.5858C13.0391 13.2107 12.5304 13 12 13C11.4696 13 10.9609 13.2107 10.5858 13.5858C10.2107 13.9609 10 14.4696 10 15C10 15.5304 10.2107 16.0391 10.5858 16.4142C10.9609 16.7893 11.4696 17 12 17ZM18 8C18.5304 8 19.0391 8.21071 19.4142 8.58579C19.7893 8.96086 20 9.46957 20 10V20C20 20.5304 19.7893 21.0391 19.4142 21.4142C19.0391 21.7893 18.5304 22 18 22H6C5.46957 22 4.96086 21.7893 4.58579 21.4142C4.21071 21.0391 4 20.5304 4 20V10C4 9.46957 4.21071 8.96086 4.58579 8.58579C4.96086 8.21071 5.46957 8 6 8H7V6C7 4.67392 7.52678 3.40215 8.46447 2.46447C9.40215 1.52678 10.6739 1 12 1C12.6566 1 13.3068 1.12933 13.9134 1.3806C14.52 1.63188 15.0712 2.00017 15.5355 2.46447C15.9998 2.92876 16.3681 3.47995 16.6194 4.08658C16.8707 4.69321 17 5.34339 17 6V8H18ZM12 3C11.2044 3 10.4413 3.31607 9.87868 3.87868C9.31607 4.44129 9 5.20435 9 6V8H15V6C15 5.20435 14.6839 4.44129 14.1213 3.87868C13.5587 3.31607 12.7956 3 12 3Z" fill="#969BAB" />
                                            </svg>
                                            Download certificaat
                                          </Link>
                                          :
                                          <Link to="#" className="btn d-block disabled mt-3">
                                            <svg className='me-2' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M12 17C12.5304 17 13.0391 16.7893 13.4142 16.4142C13.7893 16.0391 14 15.5304 14 15C14 14.4696 13.7893 13.9609 13.4142 13.5858C13.0391 13.2107 12.5304 13 12 13C11.4696 13 10.9609 13.2107 10.5858 13.5858C10.2107 13.9609 10 14.4696 10 15C10 15.5304 10.2107 16.0391 10.5858 16.4142C10.9609 16.7893 11.4696 17 12 17ZM18 8C18.5304 8 19.0391 8.21071 19.4142 8.58579C19.7893 8.96086 20 9.46957 20 10V20C20 20.5304 19.7893 21.0391 19.4142 21.4142C19.0391 21.7893 18.5304 22 18 22H6C5.46957 22 4.96086 21.7893 4.58579 21.4142C4.21071 21.0391 4 20.5304 4 20V10C4 9.46957 4.21071 8.96086 4.58579 8.58579C4.96086 8.21071 5.46957 8 6 8H7V6C7 4.67392 7.52678 3.40215 8.46447 2.46447C9.40215 1.52678 10.6739 1 12 1C12.6566 1 13.3068 1.12933 13.9134 1.3806C14.52 1.63188 15.0712 2.00017 15.5355 2.46447C15.9998 2.92876 16.3681 3.47995 16.6194 4.08658C16.8707 4.69321 17 5.34339 17 6V8H18ZM12 3C11.2044 3 10.4413 3.31607 9.87868 3.87868C9.31607 4.44129 9 5.20435 9 6V8H15V6C15 5.20435 14.6839 4.44129 14.1213 3.87868C13.5587 3.31607 12.7956 3 12 3Z" fill="#969BAB" />
                                            </svg>
                                            Download certificaat
                                          </Link>
                                      }


                                    </div>
                                  </div>
                                </div>


                              </>
                            )
                          })}
                        {
                          Frenchlanguagedata.map((data) => {
                            return (
                              <>

                                <div key={"scorelist"} className='col-xl-4 col-lg-4 col-md-6 text-center '>
                                  <div className='student-box flex-fill bg-white '>

                                    <div className='student-box-top d-flex justify-content-between align-items-center mt-4'>
                                      <h6 className='mb-0'>{(data.level_name == undefined || data.level_name == null || data.level_name == "") ? "-" : data.level_name.charAt(0).toUpperCase() + data.level_name.slice(1)}</h6>
                                      <span className={data.status == "not yet" ? "lable not" : "lable"}>{(data.status == undefined || data.status == null || data.status == "") ? "-" : (data.status == "not yet" ? "Nog niet" : "Behaald")}</span>
                                    </div>
                                    <div className='student-box-middle'>
                                      <h5>{(data.final_score == undefined || data.final_score == null || data.final_score == "") ? "-" : data.final_score}</h5>
                                      <span>Eindresultaat</span>
                                    </div>
                                    <div className='student-box-bottom'>
                                      {
                                        data.status == "not yet" ?
                                          ""
                                          :
                                          <Link to="#" className='btn d-block outline' onClick={() => handleModal(data.level_id)}>
                                            Bekijk details
                                          </Link>
                                      }

                                      {
                                        data.is_certificate_download === "true" ?
                                          <Link to="#" className="btn d-block mt-3" onClick={() => DownaloadCertificate(data)}>
                                            <svg className='me-2' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M12 17C12.5304 17 13.0391 16.7893 13.4142 16.4142C13.7893 16.0391 14 15.5304 14 15C14 14.4696 13.7893 13.9609 13.4142 13.5858C13.0391 13.2107 12.5304 13 12 13C11.4696 13 10.9609 13.2107 10.5858 13.5858C10.2107 13.9609 10 14.4696 10 15C10 15.5304 10.2107 16.0391 10.5858 16.4142C10.9609 16.7893 11.4696 17 12 17ZM18 8C18.5304 8 19.0391 8.21071 19.4142 8.58579C19.7893 8.96086 20 9.46957 20 10V20C20 20.5304 19.7893 21.0391 19.4142 21.4142C19.0391 21.7893 18.5304 22 18 22H6C5.46957 22 4.96086 21.7893 4.58579 21.4142C4.21071 21.0391 4 20.5304 4 20V10C4 9.46957 4.21071 8.96086 4.58579 8.58579C4.96086 8.21071 5.46957 8 6 8H7V6C7 4.67392 7.52678 3.40215 8.46447 2.46447C9.40215 1.52678 10.6739 1 12 1C12.6566 1 13.3068 1.12933 13.9134 1.3806C14.52 1.63188 15.0712 2.00017 15.5355 2.46447C15.9998 2.92876 16.3681 3.47995 16.6194 4.08658C16.8707 4.69321 17 5.34339 17 6V8H18ZM12 3C11.2044 3 10.4413 3.31607 9.87868 3.87868C9.31607 4.44129 9 5.20435 9 6V8H15V6C15 5.20435 14.6839 4.44129 14.1213 3.87868C13.5587 3.31607 12.7956 3 12 3Z" fill="#969BAB" />
                                            </svg>
                                            Download certificaat
                                          </Link>
                                          :
                                          <Link to="#" className="btn d-block disabled mt-3">
                                            <svg className='me-2' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M12 17C12.5304 17 13.0391 16.7893 13.4142 16.4142C13.7893 16.0391 14 15.5304 14 15C14 14.4696 13.7893 13.9609 13.4142 13.5858C13.0391 13.2107 12.5304 13 12 13C11.4696 13 10.9609 13.2107 10.5858 13.5858C10.2107 13.9609 10 14.4696 10 15C10 15.5304 10.2107 16.0391 10.5858 16.4142C10.9609 16.7893 11.4696 17 12 17ZM18 8C18.5304 8 19.0391 8.21071 19.4142 8.58579C19.7893 8.96086 20 9.46957 20 10V20C20 20.5304 19.7893 21.0391 19.4142 21.4142C19.0391 21.7893 18.5304 22 18 22H6C5.46957 22 4.96086 21.7893 4.58579 21.4142C4.21071 21.0391 4 20.5304 4 20V10C4 9.46957 4.21071 8.96086 4.58579 8.58579C4.96086 8.21071 5.46957 8 6 8H7V6C7 4.67392 7.52678 3.40215 8.46447 2.46447C9.40215 1.52678 10.6739 1 12 1C12.6566 1 13.3068 1.12933 13.9134 1.3806C14.52 1.63188 15.0712 2.00017 15.5355 2.46447C15.9998 2.92876 16.3681 3.47995 16.6194 4.08658C16.8707 4.69321 17 5.34339 17 6V8H18ZM12 3C11.2044 3 10.4413 3.31607 9.87868 3.87868C9.31607 4.44129 9 5.20435 9 6V8H15V6C15 5.20435 14.6839 4.44129 14.1213 3.87868C13.5587 3.31607 12.7956 3 12 3Z" fill="#969BAB" />
                                            </svg>
                                            Download certificaat
                                          </Link>
                                      }


                                    </div>
                                  </div>
                                </div>


                              </>
                            )
                          })}

                        {
                          Escapedata.map((data) => {
                            return (
                              <>

                                <div key={"scorelist"} className='col-xl-4 col-lg-4 col-md-6 text-center '>
                                  <div className='student-box flex-fill bg-white '>

                                    <div className='student-box-top d-flex justify-content-between align-items-center mt-4'>
                                      <h6 className='mb-0'>{(data.level_name == undefined || data.level_name == null || data.level_name == "") ? "-" : data.level_name.charAt(0).toUpperCase() + data.level_name.slice(1)}</h6>

                                    </div>
                                    <div className='student-box-middle'>
                                      <h5>{(data.average_time == undefined || data.average_time == null || data.average_time == "") ? "-" : data.average_time}</h5>
                                      <span>Eindresultaat</span>
                                    </div>
                                    <div className='student-box-bottom'>
                                      <Link to="#" className='btn d-block outline' onClick={() => handleModal("escape")}>
                                        Bekijk details
                                      </Link>


                                    </div>
                                  </div>
                                </div>


                              </>
                            )
                          })}
                        {Error ? <div key={"scorelist"} className='col-xl-4 col-lg-4 col-md-6 text-center '>
                          <div className='student-box flex-fill bg-white '>
                            <div className='student-box-top  justify-content-between align-items-center'>
                              <h6 className='mb-0'>{Error}</h6>

                            </div>
                          </div>
                        </div>
                          :
                          Zerotestdata.map((data) => {
                            return (
                              <>

                                <div key={"scorelist"} className='col-xl-4 col-lg-4 col-md-6 text-center '>
                                  <div className='student-box flex-fill bg-white '>
                                    <div className='student-box-top  justify-content-between align-items-center'>
                                      <h6 className='mb-0'>{(data.game_name == undefined || data.game_name == null || data.game_name == "") ? "-" : data.game_name.charAt(0).toUpperCase() + data.game_name.slice(1)}</h6>

                                    </div>

                                    <div className='student-box-top d-flex justify-content-between align-items-center mt-4'>
                                      <h6 className='mb-0'>{(data.level_name == undefined || data.level_name == null || data.level_name == "") ? "-" : data.level_name.charAt(0).toUpperCase() + data.level_name.slice(1)}</h6>
                                      <span className={data.status == "not yet" ? "lable not" : "lable"}>{(data.status == undefined || data.status == null || data.status == "") ? "-" : (data.status == "not yet" ? "Nog niet" : "Behaald")}</span>
                                    </div>
                                    <div className='student-box-middle'>
                                      <h5>{(data.score == undefined || data.score == null || data.score == "") ? "-" : data.score}</h5>
                                      <span>Eindresultaat</span>
                                    </div>

                                  </div>
                                </div>


                              </>
                            )
                          })}
                      </>
                  }




                </div>
              }
            </div>
            {
              ModalOpen === true ?
                <div className='detail-report'>
                  <div className="modal d-block"  >
                    <div className="modal-dialog">
                      <div className="modal-content">
                        {DownloadLoader ? <div className='loader-overlay'><Loader /></div> : ''}
                        <div className="modal-header">
                          <Link className="modal-title" id="detail-report">

                            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => setModelOpen(false)}>
                              <path d="M12 19L5 12L12 5" stroke="#121212" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M19 12H5" stroke="#121212" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            Gemiddelde per domein</Link>
                          {/* onClick={() => handleDownloadCertificate()} */}
                          {
                            Escapedata.length != 0 ?
                              ""
                              :
                              <button type="button" className="btn btn-secondary ms-auto" onClick={() => handleBulkCertificate()}>Download certificaten</button>
                          }

                        </div>
                        <div className="modal-body">
                          <div className='report-box mb-4'>
                            {
                              SeeDetails.map((element) => {
                                return (
                                  <> <h6 className='report-title mb-3'>
                                    {element.level_name}
                                  </h6>
                                    <div className='settings-widget'>

                                      <table className="table table-nowrap mb-0 common-table">
                                        <thead>
                                          <tr>
                                            {Object.keys(element.domains).map((i) => (
                                              <> <th>{element.domains[i].domain_name ? element.domains[i].domain_name : "_"}</th></>
                                            ))}



                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            {Object.keys(element.domains).map((i) => (
                                              <>
                                                {
                                                  element.domains[i].average_time ?
                                                    <td>
                                                      {element.domains[i].average_time ? element.domains[i].average_time : "_"}
                                                    </td>
                                                    :
                                                    <td>
                                                      {element.domains[i].score ? element.domains[i].score : "_"}
                                                    </td>
                                                }

                                              </>
                                            ))}

                                          </tr>
                                        </tbody>

                                      </table>
                                    </div></>
                                )
                              })
                            }



                          </div>

                          <div className="modal-footer">
                            {/* <button type="submit" className="btn btn-secondary" >Submit</button> */}
                            <button type="button" className="btn btn-secondary" onClick={() => setModelOpen(false)}>Afsluiten</button>
                          </div>
                        </div>


                      </div>
                    </div>
                  </div>
                  <div className="modal-backdrop fade show"></div>
                </div>

                : ""
            }
            {/* Instructor Dashboard */}
          </div>
        </div>
      </div>


      <Footer />
    </div>
  );
}
