import React, { useState, useEffect } from 'react';
import TeacherService from '../../../Api/Services/TeacherService';
import InstructorSidebar from "../../instructor/sidebar";
import { InstructorHeader } from "../../instructor/header";
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import SidebarTop from "../sidebartop";
const TeacherProfiles = () => {
  const [Success, setSuccess] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [file, setFile] = useState()
  const [Error, setError] = useState("")
  const navigate = useNavigate();
  const [profiledata, setProfiledata] = useState('');
  var imageUrl = process.env.REACT_APP_URL;
  useEffect(() => {
    if (localStorage.getItem('usertoken')) {

      handleTeacherProfile();


    } else {
      navigate('/login')
    }
  }, [navigate])
  const handleTeacherProfile = async () => {
    try {

      let responseData = await TeacherService.teacher_profile().json()

      if (responseData.status === true) {
        setProfiledata(responseData)



      }

    } catch (error) {
      console.log(error)
    }

  }

  const onFileChange = (e, file) => {
    if (file) {
      setFile("");
      return;
    }
    const fileReader = new FileReader()
    fileReader.readAsDataURL(e.target.files[0])
    fileReader.onload = () => {
      var image = fileReader.result
      setFile(image)
    }
  }
  const addStudent = async (event) => {

    event.preventDefault();
    if (event.target.firstname.value === "") {
      setError("Het veld voornaam is verplicht")
    }
    else if (event.target.lastname.value === "") {
      setError("Het veld achternaam is verplicht")
    }
    else if (event.target.email.value === "") {
      setError("Het e-mailveld is verplicht")
    }
    else {
      try {
        var userData = {
          "firstname": event.target.firstname.value,
          "lastname": event.target.lastname.value,
          "email": event.target.email.value,
          "image": file ? file.split(',')[1] : ""
        }

        let responseData = await TeacherService.teacher_profile_update(userData).json()
        if (responseData.status === true) {

          setSuccess(true)
          setModalMessage(responseData.message)
          setError("");
          localStorage.setItem("user", JSON.stringify(responseData.data));
        }
      } catch (error) {

        if (error.name === 'HTTPError') {
          const errorJson = await error.response.json();
          setError(errorJson.message.substr(0, errorJson.message.lastIndexOf(".")))
        }
      }
    }


  };
  return (
    <>
      <div className="main-wrapper">
        <InstructorHeader activeMenu={"Profile"} />
        <div className="page-content p-0 ">
          <div className="container-fluid">
            <div className="row">
              <div className='col-md-12 p-0'>

                {/* Sidebar */}
                <InstructorSidebar activeMenu={"Profile"} />

                <div className='full-page-content'>
                  <div className="title-bar">
                    <div className="page-title-left">
                      <h4 className="page-title mb-0">
                        Jouw gegevens
                      </h4>
                    </div>
                    <div className="page-title-right">

                      {/* SidebarTop */}
                      <SidebarTop />
                      {/* SidebarTop */}

                    </div>

                  </div>
                  <div className='row justify-content-center'>
                    <div className='col-xl-10 col-md-12'>
                      <div className="settings-widget profile-details changepassword mt-lg-5 mt-4 m-4">
                        <div className='settings-menu p-0'>

                          <div className="checkout-form personal-address edit-profile">

                            <form onSubmit={addStudent}>
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="form-group">
                                    <label className="form-control-label">Voornaam</label>
                                    <input
                                      type="text"
                                      defaultValue={profiledata.firstname}
                                      name='firstname'
                                      className="form-control"
                                      placeholder="Enter Firstname"

                                    />
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="form-group text-start">
                                    <label className="form-control-label">Achternaam </label>
                                    <input
                                      type="text"
                                      defaultValue={profiledata.lastname}
                                      name='lastname'
                                      className="form-control"
                                      placeholder="Enter Lastname"

                                    />
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="form-group text-start">
                                    <label className="form-control-label">Email</label>
                                    <input
                                      type="email"
                                      defaultValue={profiledata.email}
                                      name='email'
                                      className="form-control"
                                      placeholder="Enter Email"

                                    />
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="form-group text-start">
                                    <label className="form-control-label">Foto</label>
                                    <div className='upload-img'>
                                      {profiledata.image ? <img className="img-drop-area" height={50} width={50} name="image" src={file ? file : imageUrl + "/User/" + profiledata.image} alt='' /> : null}
                                      <input type="file" className="form-control" name='icon' id="val-number" accept="image/png, image/jpeg" onChange={(e) => onFileChange(e, file)} />
                                    </div>




                                  </div> </div>
                                {Error ? <p className=" text-center items-center justify-between rounded bg-warning py-2 text-lg text-white">{Error}</p> : ''}
                                <div className="update-profile">
                                  <button type="submit" className="btn btn-primary" >Verzend</button>

                                </div>
                              </div>
                            </form>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>






              </div>
            </div></div>
        </div>
      </div>
      <Modal show={Success}>
        <Modal.Header>
          <Modal.Title>Save</Modal.Title>
        </Modal.Header>

        <Modal.Body>{modalMessage}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {

              setFile("")
              setSuccess(false)
              navigate('/teacher-dashboard')
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TeacherProfiles;
