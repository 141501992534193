import clientAuth from "../clientAuth";


export default {

    // gamescore_index: () => clientAuth.get('student/gamescore_index'),
    gamescore_index: () => clientAuth.get('student/gamelistforstudent'),
    gamescore_show: (id) => clientAuth.post(`student/gamescore_show/${id}`),
    gamescore_add: (userdata) => clientAuth.post('student/gamescore_add', { json: userdata }),
    generatecertificate: (id) => clientAuth.get(`student/generate-certificate/${id}`),
    gamescore_list: (userdata) => clientAuth.post('student/level/wise/gamescore/list', { json: userdata }),
    index: () => clientAuth.get('student/game/list'),
    download_certificates: (id) => clientAuth.get(`student/level/wise/download/certificates/${id}`),
    scorelist:(userdata) => clientAuth.post('student/average/score/chart', { json: userdata }),
     //zero test api

     zero_test: () => clientAuth.get('student/user/zero/test'),
     //download certificate
     certificate: (level_id,game_id) => clientAuth.get(`student/user/download/certificate/${level_id}/${game_id}`),
     //Game List for user
     gamelist: (id) => clientAuth.get(`student/user/game/list/${id}`),
     //see details
     gamedetail: (id) => clientAuth.get(`student/user/game/detail/${id}`),
        //download bulk
     downloadbulk_certificates: (id) => clientAuth.get(`student/user/download/bulk/certificates/${id}`),

     //score chat
     scorechart:(userdata) => clientAuth.post('student/user/avarage/score/chart', { json: userdata }),
     
     //escape
     escapelist: () => clientAuth.get('student/user/escape/game/levelwise'),
     escapeseedetails: () => clientAuth.get('student/user/escape/levelwise/detail'),
     escapeseechart: () => clientAuth.get('student/user/escape/chart'),
     
     

     
     
   
}