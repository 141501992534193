import clientAuth from "../clientAuth";


export default {

    reports: (userdata) => clientAuth.post('teacher/student/reports', { json: userdata }),
    escapereports: (userdata) => clientAuth.post('teacher/student/escape/game/reports', { json: userdata }),
    
    // downloadCertificate: (id) => clientAuth.get(`teacher/download-certificate/${id}`),
    downloadCertificate: (userData) => clientAuth.post('teacher/download-certificate', { json: userData }),
    bulk_certificate: (userdata) => clientAuth.post('teacher/bulk-download-certificate', { json: userdata }),
    // index: () => clientAuth.get('teacher/subject/index'),
    index: () => clientAuth.get('teacher/game/list'),
    
    // reportsById: (id) => clientAuth.get(`teacher/student/reports/${id}`),
    reportsById: (userData) => clientAuth.post('teacher/student/reportbyId',{json: userData}),
    gamelist: (id) => clientAuth.get(`teacher/pass/student/reports/gamelist/${id}`),
    gameescapelist: (userdata) => clientAuth.post('teacher/student/escape/game/reportbyId', { json: userdata }),
  
    certificate: (id) => clientAuth.get(`teacher/student/reports/game/certificate/${id}`),
    levelIndex: () => clientAuth.get('teacher/level/index'),
    classIndex: () => clientAuth.get('teacher/class/index'),
    filterById: (userdata) => clientAuth.post('teacher/student/reports/filter/class/level/subject', { json: userdata })


}

