
import React, { useEffect, useState } from "react";

import { Play } from "../../../imagepath";
import { Link, useNavigate } from 'react-router-dom';
import { Modal, Button } from "react-bootstrap";
import LearningService from "../../../../Api/Services/LearningService";

const DetailsContent = (props) => {

  const [modalMessage, SetModalMessage] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [collapse, setCollapse] = useState(false);
  const navigate = useNavigate();
  const [getdata, setGetData] = useState([]);
  const [getlesson, setGetLesson] = useState('');

  const toggleCollapse = (id) => {
    //
    if (getlesson === '') {
      setCollapse(true)
      setGetLesson(id);
    }
    else if (getlesson === id) {

      setCollapse(collapse ? false : true);
      setGetLesson(id);
    }
    else {

      setCollapse(collapse ? true : false);
      setGetLesson(id);
    }

  }


  useEffect(() => {
    var propsdata = props

    setGetData(propsdata.structure)

  }, [])

  const handletaskupdate = async (lesson, module_id, task) => {



    try {
      var userdata = {
        "module_id": module_id,
        "task_id": task,
        "lesson_id": lesson,

      }
      let responseData = await LearningService.taskupdate(userdata).json()
      if (responseData.status === true) {
        SetModalMessage(responseData.message)
        setModalShow(true)
        setCollapse(true)
        var propsdata = props

        setGetData(propsdata.structure)
      }

    } catch (error) {
      console.log(error)
    }
  }
  return (
    <>
      <section className="page-content course-sec pt-4">
        {
          getdata.map((modules) => {
            return (
              <>
                <div className="container-fluid p-0">
                  <div className="row g-0">
                    <div className="col-lg-12">
                      {/* Overview */}
                      <div className="card overview-sec">
                        <div className="card-body">
                          <h5 className="subs-title">Overview</h5>
                          <h6>Course Description</h6>
                          <p dangerouslySetInnerHTML={{ __html: modules.description }} >

                          </p>


                        </div></div>
                      {/* /Overview */}
                      {/* Course Content */}

                      <div className="card content-sec">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-sm-12">
                              <h5 className="subs-title">Course Content</h5>
                            </div>

                          </div>
                          {
                            modules.tasks.map((tasks) => {
                              return (
                                <>
                                  <div className="course-card" onClick={() => toggleCollapse(tasks.id)}>
                                    <h6 className="cou-title">
                                      <Link className="collapsed" data-bs-toggle="collapse" aria-controls="example-collapse-text" onClick={() => toggleCollapse(tasks.id)}>
                                        {tasks.name_task}
                                      </Link>

                                    </h6>
                                    <div className={collapse ? "collapse show" : "collapse"}>
                                      {
                                        getlesson === tasks.id ?
                                          <>


                                            <ul>
                                              {
                                                tasks.lesson.map((element) => {
                                                  return (

                                                    <>

                                                      <li>
                                                        <p
                                                          onClick={() => navigate(`/instructor-reviews/${tasks.id}`, { state: tasks })}

                                                        >
                                                          <img src={Play} alt="" className="me-2" />
                                                          <Link
                                                            // to={`../instructor-reviews/${tasks.id}`} state={{ lesson: element ,data:tasks}}

                                                            aria-expanded="true">
                                                            {element.title}
                                                          </Link>

                                                        </p>

                                                        {
                                                          element.status == "Mark As Done"
                                                            ?
                                                            <div onClick={() => handletaskupdate(element.id, element.module_id, element.task_id)}  >
                                                              <span className="mark-lable badge mark-lable-info">Mark As Done</span>
                                                            </div>
                                                            :
                                                            <div >
                                                              <span className="mark-lable badge">Completed</span>
                                                            </div>

                                                        }



                                                      </li>
                                                    </>
                                                  )
                                                })}


                                            </ul>

                                          </>
                                          : ''
                                      }
                                    </div>

                                  </div>
                                </>
                              )
                            })
                          }



                        </div>
                      </div>
                      {/* /Course Content */}



                    </div>

                  </div>
                </div>
              </>
            )
          })
        }

      </section>
      <Modal show={modalShow}>
        <Modal.Header>
          <Modal.Title>Success</Modal.Title>
        </Modal.Header>

        <Modal.Body>{modalMessage}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setCollapse(true)
              setModalShow(false)
              window.location.reload(false);
              // navigate('/instructor-earnings')
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DetailsContent;
