import React, { useState, useEffect } from 'react';
import { InstructorHeader } from "../header";
import Footer from "../../footer";
import {


    Icon1,
    Icon2,

    StudentBgBanner,

} from "../../imagepath";
import Placeholder from '../../../assets/img/placeholder-image.png';
import { Link, useNavigate, useParams } from "react-router-dom";
import TeacherService from '../../../Api/Services/TeacherService';
import InstructorSidebar from "../sidebar";
export default function StudentInfo() {
    const [studentData, setStudentData] = useState([]);
    const [studentGameScores, setstudentGameScores] = useState([]);
    const navigate = useNavigate();
    var imageUrl = process.env.REACT_APP_URL;
    var params = useParams();
    useEffect(() => {
        if (localStorage.getItem('usertoken')) {
            var teacherdata = JSON.parse(localStorage.getItem("user"));
            if (teacherdata.roles[0].name === "Teacher") {

                handlestudentlist(params.id);
            }
            else {
                navigate('/')
            }

        } else {
            navigate('/login')
        }
    }, [navigate])
    const handlestudentlist = async (id) => {
        try {

            let responseData = await TeacherService.student_show(id).json()


            setStudentData(responseData.data)
            setstudentGameScores(responseData.data.studentGameScores)



        } catch (error) {
            console.log(error)
        }

    }

    return (
        <div className="main-wrapper">
            <InstructorHeader activeMenu={"Grid"} />
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <InstructorSidebar activeMenu={"Student List"} />
                        <div className="col-xl-10 col-lg-9 col-md-12">
                            <div className="row">

                                <div className="col-md-12">
                                    <div
                                        className="page-banner student-bg-blk"
                                        style={{ backgroundImage: "url(" + StudentBgBanner + ")" }}
                                    >
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-md-12 col-12">
                                                    <div className="profile-info-blk">
                                                        <Link to="#;" className="profile-info-img">
                                                            <img
                                                                src={studentData.image ? imageUrl + "/User/" + studentData.image : Placeholder}
                                                                alt="Profile Avatar"
                                                                className="img-fluid"
                                                            />
                                                        </Link>
                                                        <h4>
                                                            <Link to="#;">{studentData ? studentData.firstname : ''} {studentData ? studentData.lastname : ''}</Link>

                                                        </h4>

                                                        <p>Student</p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <section className="page-content course-sec">
                                        <div className="container-fluid">
                                            <div className="row">
                                                <div className="col-md-12 col-12">

                                                    <div className="card education-sec">
                                                        <div className="card-body pb-0">
                                                            <h5 className="subs-title">Games</h5>
                                                            <div className="row">
                                                                {
                                                                    studentGameScores.map((element) => {
                                                                        return <>
                                                                            <div className="col-lg-4 col-md-6 d-flex">
                                                                                <div className="course-box course-design d-flex ">
                                                                                    <div className="product">
                                                                                        <div className="product-img">
                                                                                            <Link>
                                                                                                <img
                                                                                                    className="img-fluid"
                                                                                                    alt=""
                                                                                                    src={element.game.image ? imageUrl + "/Game/" + element.game.image : Placeholder}
                                                                                                />
                                                                                            </Link>

                                                                                        </div>
                                                                                        <div className="product-content">
                                                                                            <div className="course-group d-flex">
                                                                                                <div className="course-group-img d-flex">

                                                                                                    <div className="course-name">
                                                                                                        <h4>
                                                                                                            <Link >
                                                                                                                {element.game ? element.game.title : ''}
                                                                                                            </Link>
                                                                                                        </h4>

                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <h3 className="title product-content-title">

                                                                                            </h3>
                                                                                            <div className="course-info d-flex align-items-center border-0 m-0">
                                                                                                <div className="rating-img d-flex align-items-center">
                                                                                                    <img src={Icon1} alt="" />
                                                                                                    <p>{element ? element.score : ""} Score</p>
                                                                                                </div>
                                                                                                <div className="course-view d-flex align-items-center">
                                                                                                    <img src={Icon2} alt="" />
                                                                                                    <p>{element.game ? element.game.totalscore : ""} Total Score</p>
                                                                                                </div>
                                                                                            </div>


                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </>
                                                                    })
                                                                }


                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>


                                            </div>
                                        </div>
                                    </section>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Course content */}
            <Footer />
        </div>
    );
}
