import React, { useState, useEffect } from 'react';
import { InstructorHeader } from "../header";
import Footer from "../../footer";
import { useNavigate, useParams } from "react-router-dom";

import InstructorSidebar from "../sidebar";
import SidebarTop from "../sidebartop";
import { Link } from "react-router-dom";
import Loader from "../../Loader/Loader";
import LearningService from '../../../Api/Services/LearningService';
export default function LearningPortalList() {

  const params = useParams();
  const [learningData, setLearningData] = useState([]);
  const [currentLessonIndex, setCurrentLessonIndex] = useState(0);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  var imageUrl = process.env.REACT_APP_URL;
  useEffect(() => {

    if (localStorage.getItem('usertoken')) {

      learningmodule();


    } else {
      navigate('/login')
    }
  }, [navigate])
  const learningmodule = async () => {
    try {

      let responseData = await LearningService.all_module_with_task().json()

      if (responseData.status === true) {
        var getlearningdata = responseData.Module.filter(function (item) {

          return item.id == params.id;
        });

        setLearningData(getlearningdata)
        setLoader(false)
        if (getlearningdata.length > 0) {
          const firstIncompleteIndex = getlearningdata[0].lessons.findIndex(lesson => lesson.status !== "Completed");
          setCurrentLessonIndex(firstIncompleteIndex === -1 ? getlearningdata[0].lessons.length - 1 : firstIncompleteIndex);
        }
      }

    } catch (error) {
      console.log(error)
    }
  }
  const handletaskupdate = async (lesson, module_id) => {

    setLoader(true)
    try {
      var userdata = {
        "module_id": module_id,

        "lesson_id": lesson,

      }

      let responseData = await LearningService.taskupdate(userdata).json()
      if (responseData.status === true) {

        learningmodule();
        setLoader(false)
      }

    } catch (error) {
      console.log(error)
    }
  }
  return (
    <div className="main-wrapper">
      <InstructorHeader activeMenu={"Learning"} />
      {/* Page Wrapper */}
      <div className="page-content p-0">
        <div className="container-fluid">
          <div className="row">
            <div className='col-md-12 p-0'>

              {/* Sidebar */}
              <InstructorSidebar activeMenu={"Learning"} />
              {/* Sidebar */}


              <div className='full-page-content'>
                <div className="title-bar">
                  <div className="page-title-left">
                    <h4 className="page-title">
                      <span className='back-arrow'>

                        <Link to="/learning-portal">

                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 19L5 12L12 5" stroke="#121212" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M19 12H5" stroke="#121212" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                          </svg> </Link>

                      </span>
                      Cursus informatie
                    </h4>
                  </div>
                  <div className="page-title-right">

                    {/* SidebarTop */}
                    <SidebarTop />
                    {/* SidebarTop */}

                  </div>

                </div>
                <div className='row'>
                  {/* Instructor Dashboard */}
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="row">

                      {/* Instructor List */}


                      <div className="col-lg-12">
                        <div className="common-page-padding">
                          <div className='learning-portal-list'>
                            {loader ? <Loader /> :
                              <div className='row'>

                                {
                                  learningData.map((element) => {
                                    return (
                                      <>
                                        <div className='col-lg-8'>
                                          <div className='learning-portal-img'>
                                            <div className='portal-small-logo'>
                                              <img className='img-fluid' src='../images/small-logo.png' />
                                            </div>
                                            {element.image ? <img src={imageUrl + "/Module/" + element.image} alt="User Image"
                                              className='img-fluid rounded' /> : <img className='img-fluid rounded w-100' src='images/portal-01.png' />
                                            }


                                          </div>
                                          <h5 className='learning-portal-title'>
                                            <a
                                              // href={`/learning-portal-detail/${element.id}`,{ state: tasks }}
                                              onClick={() => navigate(`/learning-portal-detail/${element.id}`, { state: element.lessons })}

                                            >
                                              {element.name}
                                            </a>
                                          </h5>
                                          <p className='description' dangerouslySetInnerHTML={{ __html: element.description }} >

                                          </p>

                                        </div>

                                        <div className='col-lg-4'>
                                          <div className='settings-widget p-4 all-lessons-box'>
                                            <div className='d-flex justify-content-between mb-3'>
                                              <h5 className='lessons-title'>
                                                Lessen
                                              </h5>
                                              <span>{element.completed_lessons_count}/{element.total_lessons}</span>
                                            </div>
                                            <div className='all-lessons-box'>
                                              {
                                                element.lessons.map((tasks, index) => {
                                                  const isCompleted = tasks.status == "Completed";
                                                  const isAccessible = index <= currentLessonIndex;
                                                  return (
                                                    <>
                                                      <div className='lessons-row' key={tasks.id}>
                                                        <label className="custom_check mr-2 mb-0 d-inline-flex remember-me d-flex justify-content-between w-100">
                                                          <div className='left-text' >
                                                            <span className='d-inline me-2'>{index + 1}. </span>     {tasks.title}

                                                          </div>
                                                          <div className='right-input'>

                                                            {" "}

                                                            <input type="checkbox" name="radio" value={tasks.id}
                                                              checked={isCompleted}
                                                              disabled={!isAccessible}
                                                              onChange={() => handletaskupdate(tasks.id, tasks.module_id)}
                                                            // defaultChecked={tasks.status === "Completed" ? true : false}
                                                            // disabled={tasks.status === "Completed" ? true : false}
                                                            // onClick={() => handletaskupdate(tasks.id, tasks.module_id)}

                                                            />

                                                            <span className="checkmark" />

                                                          </div>
                                                        </label>
                                                      </div>

                                                    </>
                                                  )
                                                })}
                                            </div>

                                          </div>


                                        </div>


                                      </>
                                    )
                                  })}


                              </div >
                            }

                          </div>





                        </div></div>



                    </div>


                  </div>
                  {/* Instructor Dashboard */}

                </div>

              </div>

            </div>


          </div>
        </div>
      </div>
      {/* Page Wrapper */}
      <Footer />
    </div>
  );
}
