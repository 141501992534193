
import {  useNavigate } from 'react-router-dom';
import { useEffect } from "react";

const UserToken = () => {
    const navigate = useNavigate();
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const gettoken = params.get('token');
    const getuserid = params.get('userid');
    const getfirstname = params.get('firstname');
    const getemail = params.get('email');
    const getrolename = params.get('rolename');


    useEffect(() => {
        let userData = {
            userId: getuserid,
            firstname: getfirstname,
            Email: getemail,
            Role: getrolename


        }
       
        localStorage.setItem("user", JSON.stringify(userData));
        localStorage.setItem("usertoken", JSON.stringify(gettoken));
        if (getrolename === "Student") {
            navigate("/student-dashboard");
        }
        else {
            navigate("/teacher-dashboard");
        }
    }, []);



}

export default UserToken;