import React, { useRef, useState, useEffect } from "react";
import { LogOut, Home } from "react-feather";
import { Link, useNavigate } from "react-router-dom";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import {
  logo,


} from "../../imagepath";
import StudentService from '../../../Api/Services/StudentService';
import UserService from "../../../Api/Services/UserService";
// eslint-disable-next-line react/prop-types

export default function StudentHeader() {
  const [navbar, setNavbar] = useState(false);
  // var imageUrl = process.env.REACT_APP_URL;

  const [showProfile, setShowProfile] = useState(false);

  // Mobile Menu toggle
  const [mobileMenu, setMobileMenu] = useState(false);

  var imageUrl = process.env.REACT_APP_URL;
  const openMobileMenu = () => {
    document.body.classList.add("menu-opened");
    setMobileMenu(true);
  };
  const hideMobileMenu = () => {
    document.body.classList.remove("menu-opened");
    setMobileMenu(false);
  };



  // To close the modal, when clicked outside anywhere




  const profile = useRef();
  useOnClickOutside(profile, () => setShowProfile(false));




  const profileClick = (e) => {
    e.preventDefault();
    setShowProfile(!showProfile);
  };

  const changeHeaderBackground = () => {
    if (window.scrollY >= 60) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  window.addEventListener("scroll", changeHeaderBackground);
  const navigate = useNavigate();

  const [profiledata, setProfiledata] = useState('');
  useEffect(() => {
    if (localStorage.getItem('usertoken')) {


      handleTeacherProfile()
    }
  }, [])

  const handleTeacherProfile = async () => {
    try {

      let responseData = await StudentService.student_profile().json()

      if (responseData.status === true) {
        setProfiledata(responseData)



      }

    } catch (error) {
      console.log(error)
    }

  }
  const handleLogout = async () => {
    try {



      const data = await UserService.user_logout().json();
      localStorage.removeItem("usertoken");
        localStorage.removeItem("user");
      if (data.status === true) {
        localStorage.removeItem("usertoken");
        localStorage.removeItem("user");
        navigate('/');
      }




    } catch (error) {
      console.log(error)
    }
  }
  return (
    <header className="header header-page theme-header">
      <div className="header-fixed">
        <nav
          className={
            navbar
              ? "navbar navbar-expand-lg header-nav scroll-sticky add-header-bg"
              : "navbar navbar-expand-lg header-nav scroll-sticky"
          }
        >
          <div className="container-fluid justify-content-start ">
            <div className="navbar-header ">
              <Link id="mobile_btn" to="#;" onClick={openMobileMenu}>
                <span className="bar-icon">
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
              </Link>
              <Link  className="navbar-brand logo">
                <img src={logo} className="img-fluid" alt="Logo" />
              </Link>
            </div>
            <div className="main-menu-wrapper ms-4">
              <div className="menu-header">
                <Link  className="menu-logo">
                  <img src={logo} className="img-fluid" alt="Logo" />
                </Link>
                <Link
                  id="menu_close"
                  className="menu-close"
                  to="#;"
                  onClick={hideMobileMenu}
                >
                  <i className="fas fa-times"></i>
                </Link>
              </div>
              <ul className="main-nav">
                <li className={window.location.pathname === '/student-dashboard' ? 'has-submenu active' : 'has-submenu '}>
                  <Link to="/student-dashboard" >
                    Overzicht
                  </Link>

                </li>

                <li className={window.location.pathname === '/student-report' ? 'has-submenu active' : 'has-submenu '}>
                  <Link to="/student-report">
                    Statistieken

                  </Link>

                </li>



                <li className="login-link">
                  <Link to="/login" onClick={hideMobileMenu}>
                    Login / Signup
                  </Link>
                </li>
              </ul>
            </div>
            <ul className="nav header-navbar-rht ms-auto">
              <li className="nav-item nav-search">
                <div className='comon-search-group'>
                  <span className='search-icon'>

                    {/* <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7.66536 14.0002C11.1632 14.0002 13.9987 11.1646 13.9987 7.66683C13.9987 4.16903 11.1632 1.3335 7.66536 1.3335C4.16756 1.3335 1.33203 4.16903 1.33203 7.66683C1.33203 11.1646 4.16756 14.0002 7.66536 14.0002Z" stroke="#969BAB" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M14.6654 14.6668L13.332 13.3335" stroke="#969BAB" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg> */}

                  </span>
                  {/* <input type="text" className="form-control" placeholder="Zoeken"></input> */}

                </div>

              </li>
              {/* <li className="nav-item noti-nav">
                <span className="noti-new">

                </span>
                <Link className="dropdown-item " onClick={handleLogout}>

                  <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.018 3.16748C7.98388 3.16748 5.51805 5.63331 5.51805 8.66748V11.3166C5.51805 11.8758 5.27971 12.7283 4.99555 13.205L3.94138 14.9558C3.29055 16.0375 3.73971 17.2383 4.93138 17.6416C8.88221 18.9616 13.1447 18.9616 17.0955 17.6416C18.2047 17.275 18.6905 15.9641 18.0855 14.9558L17.0314 13.205C16.7564 12.7283 16.518 11.8758 16.518 11.3166V8.66748C16.518 5.64248 14.043 3.16748 11.018 3.16748Z" stroke="#61677A" strokeWidth="1.8" strokeMiterlimit="10" strokeLinecap="round" />
                    <path d="M12.7139 3.43332C12.4298 3.35082 12.1364 3.28665 11.8339 3.24999C10.9539 3.13999 10.1106 3.20415 9.32227 3.43332C9.5881 2.75499 10.2481 2.27832 11.0181 2.27832C11.7881 2.27832 12.4481 2.75499 12.7139 3.43332Z" stroke="#61677A" strokeWidth="1.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M13.7676 17.9717C13.7676 19.4842 12.5301 20.7217 11.0176 20.7217C10.2659 20.7217 9.56924 20.41 9.07424 19.915C8.57924 19.42 8.26758 18.7233 8.26758 17.9717" stroke="#61677A" strokeWidth="1.8" strokeMiterlimit="10" />
                  </svg>


                </Link>


              </li> */}




              <li className="nav-item user-nav">
                <Link
                  to="#"
                  className={
                    showProfile ? "dropdown-toggle show" : "dropdown-toggle"
                  }
                  data-bs-toggle="dropdown"
                  onClick={profileClick}
                >
                  <span className="user-img">
                  {profiledata.image ? <img src={imageUrl + "/User/" + profiledata.image} alt=''  className='img-fluid'/> : null}
                    {/* {profiledata.image ? <img src={imageUrl + "/User/" + profiledata.image} alt='' /> : null} */}
                    {/* <img className='img-fluid' src={imageUrl + "/User/" + profiledata.image} /> */}
                    <span className="status online"><i className="fas fa-angle-down"></i></span>

                    <span className="status online"></span>
                  </span>
                </Link>
                <div
                  ref={profile}
                  className={
                    showProfile
                      ? "users dropdown-menu dropdown-menu-right show modalPosition"
                      : "users dropdown-menu dropdown-menu-right"
                  }
                  data-popper-placement="bottom-end"
                >
                  <div className="user-header">
                    <div className="avatar avatar-sm">
                      {/* {profiledata.image ? <img src={imageUrl + "/User/" + profiledata.image} alt="User Image"
                        className="avatar-img rounded-circle" /> : null} */}
                      {/* <img className='img-fluid' src='images/user11.jpg' /> */}
                      {/* <img className='img-fluid' src={imageUrl + "/User/" + profiledata.image} /> */}
                      {profiledata.image ? <img src={imageUrl + "/User/" + profiledata.image} alt=''  className='img-fluid'/> : null}
                    </div>
                    <div className="user-text">
                      <h6>{profiledata.firstname} {profiledata.lastname}</h6>
                      <p className="text-muted text mb-0 profile-name-text">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M8 13.3337V6.66699" stroke="#61677A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M12 13.3337V2.66699" stroke="#61677A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M4 13.3337V10.667" stroke="#61677A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        {(profiledata.levelname == undefined || profiledata.levelname == null || profiledata.levelname == "") ? "-" : profiledata.levelname.charAt(0).toUpperCase() + profiledata.levelname.slice(1)}</p>
                    </div>
                  </div>


                  <Link
                    className="dropdown-item "
                    to="/student-profile"
                  >

                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.1341 9.05866C10.0508 9.05033 9.95078 9.05033 9.85911 9.05866C7.87578 8.99199 6.30078 7.36699 6.30078 5.36699C6.30078 3.32533 7.95078 1.66699 10.0008 1.66699C12.0424 1.66699 13.7008 3.32533 13.7008 5.36699C13.6924 7.36699 12.1174 8.99199 10.1341 9.05866Z" stroke="#121212" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M5.96758 12.133C3.95091 13.483 3.95091 15.683 5.96758 17.0247C8.25924 18.558 12.0176 18.558 14.3092 17.0247C16.3259 15.6747 16.3259 13.4747 14.3092 12.133C12.0259 10.608 8.26758 10.608 5.96758 12.133Z" stroke="#121212" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    Gegevens </Link>


                  <Link
                    className="dropdown-item "
                    to="/change-password"
                  >


                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9.99935 14.1667C10.4596 14.1667 10.8327 13.7936 10.8327 13.3333C10.8327 12.8731 10.4596 12.5 9.99935 12.5C9.53911 12.5 9.16602 12.8731 9.16602 13.3333C9.16602 13.7936 9.53911 14.1667 9.99935 14.1667Z" stroke="#121212" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M15.8333 8.33301H4.16667C3.24619 8.33301 2.5 9.0792 2.5 9.99967V16.6663C2.5 17.5868 3.24619 18.333 4.16667 18.333H15.8333C16.7538 18.333 17.5 17.5868 17.5 16.6663V9.99967C17.5 9.0792 16.7538 8.33301 15.8333 8.33301Z" stroke="#121212" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M5.83398 8.33348V5.83348C5.83402 4.91629 6.1367 4.02473 6.69506 3.29709C7.25343 2.56944 8.03629 2.04636 8.92223 1.80897C9.80816 1.57158 10.7477 1.63315 11.5951 1.98412C12.4424 2.33509 13.1503 2.95586 13.609 3.75014" stroke="#121212" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>

                    Wijzig wachtwoord </Link>


                  <Link
                    className="dropdown-item "
                    onClick={handleLogout}
                  >

                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7.41602 6.3002C7.67435 3.3002 9.21602 2.0752 12.591 2.0752H12.6993C16.4243 2.0752 17.916 3.56686 17.916 7.29186V12.7252C17.916 16.4502 16.4243 17.9419 12.6993 17.9419H12.591C9.24102 17.9419 7.69935 16.7335 7.42435 13.7835" stroke="#121212" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M12.5009 10H3.01758" stroke="#121212" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M4.87565 7.20801L2.08398 9.99967L4.87565 12.7913" stroke="#121212" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>

                    Uitloggen </Link>
                  <Link
                    className="dropdown-item d-none "
                    to="/student-dashboard"
                  >
                    <Home size={16} color={"#FF875A"} className="headerIcon mx-2" />{" "}
                    Dashboard
                  </Link>


                  <Link className="dropdown-item d-none" onClick={handleLogout}>
                    <LogOut
                      size={16}
                      color={"#FF875A"}
                      className="headerIcon mx-2"
                    />{" "}
                    Logout
                  </Link>
                </div>
              </li>
            </ul>
          </div>
        </nav>
        <div
          className={mobileMenu ? "sidebar-overlay opened" : "sidebar-overlay"}
        ></div>
      </div>
    </header>
  );
}
