import React, { useState, useEffect } from 'react';
import { InstructorHeader } from "../header";
import Footer from "../../footer";
import { useNavigate } from "react-router-dom";

import InstructorSidebar from "../sidebar";
import SidebarTop from "../sidebartop";

import Loader from "../../Loader/Loader";
import LearningService from '../../../Api/Services/LearningService';

export default function LearningPortal() {


  const [AllModuletask, setAllModuletask] = useState([]);
  const [loader, setLoader] = useState(true);
  const navigate = useNavigate();

  var imageUrl = process.env.REACT_APP_URL;
  useEffect(() => {
    if (localStorage.getItem('usertoken')) {

      AllModule()


    } else {
      navigate('/login')
    }
  }, [])

  const AllModule = async () => {
    try {
      let responseData = await LearningService.all_module_with_task().json()
      if (responseData.status === true) {
        setAllModuletask(responseData.Module)
        setLoader(false)
      }

    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className="main-wrapper">
      <InstructorHeader activeMenu={"Learning"} />
      {/* Page Wrapper */}
      <div className="page-content p-0">
        <div className="container-fluid">
          <div className="row">
            <div className='col-md-12 p-0'>

              {/* Sidebar */}
              <InstructorSidebar activeMenu={"Learning"} />
              {/* Sidebar */}


              <div className='full-page-content'>
                <div className="title-bar">
                  <div className="page-title-left">
                    <h4 className="page-title">
                      Ondersteuning
                    </h4>
                  </div>
                  <div className="page-title-right">

                    {/* SidebarTop */}
                    <SidebarTop />
                    {/* SidebarTop */}

                  </div>

                </div>
                <div className='common-page-padding'>
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="row">
                      <div className="col-md-12">


                      </div>
                      {/* Instructor List */}
                      <div className="col-lg-12">
                        <div className='learning-portal padding-none'>
                          {loader ? <Loader /> :
                            <div className='row'>
                              {
                                AllModuletask.map((element) => {
                                  return (
                                    <>
                                      <div className='col-xl-4 col-lg-6 mb-4 p-0 common-padding'>
                                        <div className='learning-portal-box'>
                                          <div className='learning-portal-img text-center'>


                                            {element.image ? <img src={imageUrl + "/Module/" + element.image} alt="User Image"
                                              className='img-fluid' /> : <img className='img-fluid w-100' src='images/portal-01.png' />
                                            }

                                          </div>
                                          <div className='learning-portal-content'>
                                            <h6 className='learning-portal-title'>
                                              <a href={`/learning-portal-list/${element.id}`} >
                                                {element.name}
                                              </a>

                                            </h6>
                                            <span className='lerning-portal-lesson'>
                                              {element.total_lessons} Lessen
                                            </span>
                                            <div className='lerning-portal-progress'>
                                              <span className='lerning-portal-percentage'>
                                                {
                                                  element.total_lessons === element.completed_lessons_count
                                                    ?
                                                    '100%' :
                                                    element.percentage + "%"


                                                }

                                              </span>
                                              <div className='course-stip progress-stip'>
                                                <div className="progress-bar bg-success progress-bar-striped" role="progressbar" style={{ width: element.percentage + "%" }} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>


                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                      </div>
                                    </>
                                  )
                                })
                              }

                            </div>

                          }
                        </div>





                      </div>



                    </div>


                  </div>
                  {/* Instructor Dashboard */}
                </div>

              </div>


            </div>


          </div>
        </div>
      </div>
      {/* Page Wrapper */}
      <Footer />
    </div>
  );
}
