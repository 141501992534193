import React, { useState, useEffect } from 'react';
import { InstructorHeader } from "../header";
import Footer from "../../footer";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import InstructorSidebar from "../sidebar";
import SidebarTop from "../sidebartop";
import Loader from "../../Loader/Loader";
import StudentReporting from '../../../Api/Services/StudentReporting';
import TeacherService from '../../../Api/Services/TeacherService';
// import { Modal, Button } from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import DatePicker from "react-datepicker";
export default function EscapeReports() {
    const [isOpen, setIsOpen] = useState(false);
    const [date, setDate] = useState("");

    const [isOpen2, setIsOpen2] = useState(false);


    const [studentList, setStudentList] = useState([]);
    const [searchItem, setSearchItem] = useState('')
    const [FilterList, setFilteredList] = useState([]);
    const [ErrorMsg, setErrorMsg] = useState('');
    // const [FilterData, setFilterData] = React.useState([]);
    const [ClassIndex, setClassIndex] = useState([]);
    const [LevelIndex, setLevelIndex] = useState([]);
    const [classId, setClassId] = useState('');
    const [levelId, setLevelId] = useState('');
    const [ActiveClass, setActiveClass] = useState('');
    const [levelClass, setlevelClass] = useState('');
    // const [certificateClass, setcertificateClass] = useState('');
    const [AvarageClass, setAvarageClass] = useState('')
    // const [certificateId, setCertificateId] = useState('')
    const [AvarageId, setAvarageId] = useState([])
    const navigate = useNavigate();
    const [loader, setLoader] = useState(true);
    const [DetailLoader, setDetailLoader] = useState(true);
    const [DetailReport, setDetailReport] = useState([]);
    // const [GameList, setGameList] = useState([]);

    var teacherdata = JSON.parse(localStorage.getItem("user"));
    // const [modalMessage, setModalMessage] = useState('');
    // const [ModalOpen, setModalOpen] = useState(false);
    useEffect(() => {
        if (localStorage.getItem('usertoken')) {



            reportList()
            classIndexList()
            levelIndexList()


        } else {
            navigate('/login')
        }

    }, [])

    const toggle = async (id) => {
        setIsOpen((isOpen) => !isOpen);

        setDetailLoader(true)
        try {
            let userData = {
                "student_id": id,

            }

            let responseData = await StudentReporting.gameescapelist(userData).json()



            if (responseData.status === true) {

                setDetailReport(responseData.levels)
                setDetailLoader(false)
            }




        } catch (error) {
            console.log(error)
        }
    }

    function toggle2() {
        setIsOpen2((isOpen2) => !isOpen2);
        setActiveClass('')
        setAvarageClass('')
        setlevelClass('')
    }


    const classIndexList = async () => {
        try {

            let responseData = await TeacherService.teacher_profile().json()
            console.log(responseData.classroom, "classroom")
            setClassIndex(responseData.classroom)

        } catch (error) {
            console.log(error)
        }

    }
    const levelIndexList = async () => {
        try {

            let responseData = await StudentReporting.levelIndex().json()
            console.log(responseData, "level indes")
            setLevelIndex(responseData.data)


        } catch (error) {
            console.log(error)
        }

    }
    const reportList = async () => {
        try {


            let responseData = await StudentReporting.escapereports().json()
            setLoader(false)
            if (responseData.status === true) {
                setStudentList(responseData.data)
                setFilteredList(responseData.data)
                console.log(teacherdata)

            }
            else {
                setErrorMsg(responseData.message)
                console.log(responseData, "data")
                setFilteredList([])
            }



        } catch (error) {
            console.log(error)
        }
    }

    const handleInputChange = (e) => {
        const searchTerm = e.target.value;
        setSearchItem(searchTerm)
        const filteredItems = studentList.filter((user) =>
            user.classroom ? user.classroom.toLowerCase().includes(searchTerm.toLowerCase()) || user.firstname.toLowerCase().includes(searchTerm.toLowerCase()) || user.lastname.toLowerCase().includes(searchTerm.toLowerCase()) : user.firstname.toLowerCase().includes(searchTerm.toLowerCase()) || user.lastname.toLowerCase().includes(searchTerm.toLowerCase() || user.timing.isNumber().includes(searchTerm.isNumber()))
        );

        setFilteredList(filteredItems);
    }



    const handleFilter = async () => {
        try {
            var userData = {
                "classname": classId,
                // "subject_id": certificateId,
                "level_id": levelId,

                "average_time": AvarageId,
                "gamedate": moment(date).format('YYYY-MM-DD')
            }
            let responseData = await StudentReporting.escapereports(userData).json()

            if (responseData.status === true) {
                setFilteredList(responseData.data)
                setActiveClass('')
                setAvarageClass('')
                setlevelClass('')
                setDate('')
                // setcertificateClass(' ')
                setIsOpen2(false)
            }


        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div className="main-wrapper">
            <InstructorHeader activeMenu={"EsReports"} />
            {/* Page Wrapper */}
            <div className="page-content p-0">
                <div className="container-fluid">
                    <div className="row">
                        <div className='col-md-12 p-0'>

                            {/* Sidebar */}
                            <InstructorSidebar activeMenu={"EsReports"} />
                            {/* Sidebar */}

                            <div className='full-page-content'>
                                <div className="title-bar">
                                    <div className="page-title-left">
                                        <h4 className="page-title">
                                            OntSNAP de wiskunde
                                        </h4>
                                    </div>
                                    <div className="page-title-right">

                                        {/* SidebarTop */}
                                        <SidebarTop />
                                        {/* SidebarTop */}

                                    </div>

                                </div>
                                <div className='row'>
                                    <div className="col-xl-12 col-lg-12 col-md-12">
                                        {loader ? <Loader /> :
                                            <div className="row">
                                                <div className="col-md-12">


                                                </div>
                                                {/* Instructor List */}
                                                {
                                                    ErrorMsg ?
                                                        <div key={"scorelist"} className='col-xl-12 col-lg-12 col-md-6 text-center justi-content-center '>
                                                            <div className='col-xl-10 m-auto mt-5 '>
                                                                <div className='student-box flex-fill bg-white '>
                                                                    <div className='student-box-top  justify-content-between align-items-center'>
                                                                        <h6 className='mb-0'>{ErrorMsg}</h6>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <>
                                                            <div className="col-lg-12">
                                                                <div className="common-page-padding">
                                                                    <div className='student-report-content'>
                                                                        <div className='top-filter d-flex justify-content-between'>
                                                                            <div className='top-filter-right d-flex '>
                                                                            </div>
                                                                            <div className='top-filter-right d-flex '>
                                                                                <div className='comon-search-group'>
                                                                                    <span className='search-icon'>

                                                                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                            <path d="M7.66536 14.0002C11.1632 14.0002 13.9987 11.1646 13.9987 7.66683C13.9987 4.16903 11.1632 1.3335 7.66536 1.3335C4.16756 1.3335 1.33203 4.16903 1.33203 7.66683C1.33203 11.1646 4.16756 14.0002 7.66536 14.0002Z" stroke="#969BAB" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                                                            <path d="M14.6654 14.6668L13.332 13.3335" stroke="#969BAB" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                                                        </svg>

                                                                                    </span>
                                                                                    <input type="text" className="form-control" placeholder="Leerling zoeken" value={searchItem}
                                                                                        onChange={handleInputChange}></input>

                                                                                </div>


                                                                                <div className='filter-button d-flex'>
                                                                                    <Link to="" className="btn" onClick={() => setIsOpen2(true)}>
                                                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                            <path d="M18.334 5.4165H13.334" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                                                            <path d="M4.99935 5.4165H1.66602" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                                                            <path d="M8.33268 8.33333C9.94351 8.33333 11.2493 7.0275 11.2493 5.41667C11.2493 3.80584 9.94351 2.5 8.33268 2.5C6.72185 2.5 5.41602 3.80584 5.41602 5.41667C5.41602 7.0275 6.72185 8.33333 8.33268 8.33333Z" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                                                            <path d="M18.3333 14.5835H15" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                                                            <path d="M6.66602 14.5835H1.66602" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                                                            <path d="M11.6667 17.4998C13.2775 17.4998 14.5833 16.194 14.5833 14.5832C14.5833 12.9723 13.2775 11.6665 11.6667 11.6665C10.0558 11.6665 8.75 12.9723 8.75 14.5832C8.75 16.194 10.0558 17.4998 11.6667 17.4998Z" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                                                        </svg>
                                                                                        Filter
                                                                                    </Link>
                                                                                    {/* <div className='top-filter-right'>
                                                                                        <Link to="" className="btn btn-primary">Download Certificaten
                                                                                        </Link>
                                                                                    </div> */}
                                                                                </div>

                                                                            </div>



                                                                        </div>

                                                                        <div className='studentreport-table mt-4'>
                                                                            <div className='settings-widget'>
                                                                                <div className=' p-0'>
                                                                                    <div className='comman-space p-0'>
                                                                                        <table className='table table-nowrap mb-0 common-table'>
                                                                                            <thead>
                                                                                                <tr>
                                                                                                    <th>
                                                                                                        {" "}
                                                                                                        Datum

                                                                                                    </th>
                                                                                                    <th>Voornaam</th>
                                                                                                    <th>Achternaam</th>
                                                                                                    <th>Klas</th>
                                                                                                    <th>Ontsnapt</th>
                                                                                                    <th>Tijd</th>
                                                                                                    <th className='text-transform-none'>Aantal goed</th>

                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                {
                                                                                                    FilterList.length === 0 ?
                                                                                                        <tr>
                                                                                                            <td className='text-bold' >

                                                                                                                Data not found
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                        :

                                                                                                        FilterList.map((element, index) => {
                                                                                                            return (
                                                                                                                <>
                                                                                                                    <tr>
                                                                                                                        {/* <td className='text-bold' key={index}> */}
                                                                                                                        <td key={index}>
                                                                                                                            {new Date(element.create_date).toISOString()
                                                                                                                                .slice(0, 10)
                                                                                                                                .split("-")
                                                                                                                                .reverse()
                                                                                                                                .join("-")}
                                                                                                                        </td>
                                                                                                                        <td>  {element.firstname}</td>
                                                                                                                        <td>{element.lastname} </td>
                                                                                                                        <td>{element.classroom ? element.classroom : '_'}</td>
                                                                                                                        <td>{element.escape_game ? element.escape_game : "_"}</td>
                                                                                                                        <td>{element.timing ? element.timing : "_"}</td>
                                                                                                                        <td>
                                                                                                                            {element.correct ? element.correct : "_"}
                                                                                                                            {/* <Link onClick={() => toggle(element.id)} to="" className="btn border-btn text-transform-none">  Zie details
                                                                                                                            </Link> */}


                                                                                                                        </td>


                                                                                                                    </tr>


                                                                                                                </>
                                                                                                            )
                                                                                                        })

                                                                                                }




                                                                                            </tbody>

                                                                                        </table>

                                                                                    </div>

                                                                                </div>
                                                                            </div>

                                                                        </div>


                                                                    </div>




                                                                </div>
                                                            </div>

                                                        </>
                                                }



                                            </div>
                                        }

                                    </div>
                                    {/* Instructor Dashboard */}
                                </div>

                            </div>


                        </div>
                        {isOpen2 &&

                            <div className='download-option'>
                                <div className="modal d-block"  >
                                    <div className="modal-dialog">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h1 className="modal-title fs-5" id="exampleModalLabel">Filters  </h1>
                                                <button type="button" className="btn-close" onClick={() => setIsOpen2(false)}></button>
                                            </div>
                                            <div className="modal-body">
                                                <div className='download-box'>
                                                    <h6 className='download-title mb-3'>
                                                        Datum
                                                    </h6>

                                                    <DatePicker selected={date} onChange={(date) => setDate(date)} className='form-control' placeholderText="Selecteer een datum" />
                                                </div>
                                                <div className='download-box'>
                                                    <h6 className='download-title mb-3'>
                                                        Student Klas
                                                    </h6>
                                                    <ul className='comom-lable-list'>
                                                        <li onClick={() => {

                                                            setClassId("")
                                                            setActiveClass("All");
                                                        }}>
                                                            <Link to="" className={ActiveClass === "All" ? 'active' : ''}>  Alle Klassen</Link>
                                                        </li>
                                                        {
                                                            ClassIndex.map((element, index) => {
                                                                return (
                                                                    <><li onClick={() => {

                                                                        setClassId(element)
                                                                        setActiveClass(index);

                                                                    }} >
                                                                        <Link to="" className={ActiveClass === index ? 'active' : ''}>{element} </Link>
                                                                    </li></>
                                                                )
                                                            })
                                                        }

                                                    </ul>

                                                </div>
                                                <div className='download-box'>
                                                    <h6 className='download-title mb-3'>
                                                        Niveau leerling

                                                    </h6>
                                                    <ul className='comom-lable-list'>
                                                        <li onClick={() => {

                                                            setLevelId("")
                                                            setlevelClass("All")
                                                        }}>
                                                            <Link to="" className={levelClass === "All" ? 'active' : ''}> <li className='text-transform-none'>{`Alle niveau's`}</li></Link>
                                                        </li>
                                                        {
                                                            LevelIndex.map((element, index) => {
                                                                return (
                                                                    <>
                                                                        {
                                                                            element.title === "Intermediate" ?
                                                                                ""
                                                                                :
                                                                                <li onClick={() => {

                                                                                    setLevelId(element.id)
                                                                                    setlevelClass(index)
                                                                                }}>
                                                                                    <Link to="" className={levelClass === index ? 'active' : ''}>{element.title ? element.title : '_'}</Link>
                                                                                </li>}
                                                                    </>
                                                                )
                                                            })
                                                        }

                                                    </ul>

                                                </div>

                                                <div className='download-box'>
                                                    <h6 className='download-title mb-3'>
                                                        Gemiddelde tijd
                                                    </h6>
                                                    <ul className='comom-lable-list'>
                                                        <li onClick={() => {

                                                            setAvarageId([])
                                                            setAvarageClass("All")
                                                        }}>
                                                            <Link to="" className={AvarageClass === "All" ? 'active' : ''}>  Alle</Link>
                                                        </li>
                                                        <li onClick={() => {

                                                            setAvarageId([0, 5])
                                                            setAvarageClass("0-5")
                                                        }}>
                                                            <Link to="" className={AvarageClass === "0-5" ? 'active' : ''}> 0-5</Link>
                                                        </li>
                                                        <li onClick={() => {

                                                            setAvarageId([5, 10])
                                                            setAvarageClass("5-10")
                                                        }}>
                                                            <Link to="" className={AvarageClass === "5-10" ? 'active' : ''}>  5-10</Link>
                                                        </li>
                                                        <li onClick={() => {

                                                            setAvarageId([10])
                                                            setAvarageClass('10+')
                                                        }}>
                                                            <Link to="" className={AvarageClass === "10+" ? 'active' : ''}>  10+</Link>
                                                        </li>

                                                    </ul>

                                                </div>
                                                <div className="modal-footer">

                                                    <button type="button" className="btn btn-secondary btn-outline" onClick={toggle2}>Annuleren</button>
                                                    <button type="submit" className="btn btn-secondary" onClick={() => handleFilter()}>Toepassen</button>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                                <div className="modal-backdrop fade show"></div>
                            </div>

                        }
                        {isOpen &&
                            <div className='detail-report'>
                                <div className="modal d-block"  >
                                    <div className="modal-dialog">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <Link className="modal-title" id="detail-report">

                                                    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => setIsOpen(false)}>
                                                        <path d="M12 19L5 12L12 5" stroke="#121212" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M19 12H5" stroke="#121212" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                    Details</Link>



                                            </div>
                                            {DetailLoader ? <Loader /> :
                                                <div className="modal-body">

                                                    {DetailReport.map((element) => {
                                                        return (
                                                            <>
                                                                <h6 className='report-title mb-3'>
                                                                    {element.level_name}
                                                                </h6>
                                                                <div className='settings-widget'>

                                                                    <table className="table table-nowrap mb-0 common-table">
                                                                        <thead>
                                                                            <tr>
                                                                                {Object.keys(element.domains).map((i) => (
                                                                                    <> <th>{element.domains[i].domain_name ? element.domains[i].domain_name : "_"}</th></>
                                                                                ))}



                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                {Object.keys(element.domains).map((i) => (
                                                                                    <>
                                                                                        <td>
                                                                                            {element.domains[i].average_time ? element.domains[i].average_time : "_"}
                                                                                        </td>
                                                                                    </>
                                                                                ))}

                                                                            </tr>
                                                                        </tbody>

                                                                    </table>
                                                                </div></>
                                                        )
                                                    })}
                                                    <div className="modal-footer">
                                                        {/* <button type="submit" className="btn btn-secondary" >Submit</button> */}
                                                        <button type="button" className="btn btn-secondary" onClick={toggle}>Sluiten</button>
                                                    </div>
                                                </div >
                                            }

                                        </div>
                                    </div>
                                </div>
                                <div className="modal-backdrop fade show"></div>

                            </div>

                        }



                    </div>
                </div>
            </div>



            {/* <Modal className='selectgame' show={ModalOpen} onHide={() => setModalOpen(false)}>

        <p className='selectgametext' style={{ textAlign: 'center', marginTop: '10%' }}>    {modalMessage}</p>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setModalOpen(false)
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal> */}
            {/* Page Wrapper */}
            <Footer />
        </div >
    );
}
