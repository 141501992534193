import React, { useEffect } from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
// import OwlCarousel from "react-owl-carousel";
import { logo } from "../../imagepath";
// import FeatherIcon from "feather-icons-react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Button } from "react-bootstrap";
import UserService from "../../../Api/Services/UserService";


const Login = () => {

  // const [passwordType, setPasswordType] = useState("password");
  const [error, setError] = useState("")
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.removeItem('usertoken');
  }, [])
  // const togglePassword = () => {
  //   if (passwordType === "password") {
  //     setPasswordType("text")
  //     return;
  //   }
  //   setPasswordType("password")
  // }


  // var settings = {
  //   //autoWidth: true,
  //   items: 2,
  //   margin: 25,
  //   dots: true,
  //   nav: true,
  //   navText: [
  //     '<i className="fas fa-arrow-left"></i>',
  //     '<i className="fas fa-arrow-right"></i>',
  //   ],

  //   loop: true,
  //   responsiveClass: true,
  //   responsive: {
  //     0: {
  //       items: 1,
  //     },
  //     768: {
  //       items: 1,
  //     },
  //     1170: {
  //       items: 1,
  //     },
  //   },
  // };
  const handlesubmit = async (event) => {
    event.preventDefault();
    try {
      var userData = {
        "email": event.target.email.value,
        "password": event.target.password.value,

      }
      let responseData = await UserService.user_login(userData).json()
      if (responseData.stutas === true) {
        localStorage.setItem("user", JSON.stringify(responseData.user));
        localStorage.setItem("usertoken", JSON.stringify(responseData.token));
        var teacherdata = responseData.user.roles
        if (teacherdata[0].name === "Teacher") {
          navigate("/teacher-dashboard");
        }
        else {
          navigate("/student-dashboard");
        }

      }

    } catch (error) {
      if (error.name === 'HTTPError') {
        const errorJson = await error.response.json();

        setError(errorJson.message)
      } else {
        setError("The provided credentials are incorrect.")
      }
    }

  }
  return (
    <>
      <div className="main-wrapper log-wrap">
        <div className="row">
          {/* Login Banner */}
          {/* <div className="col-md-6 login-bg">
            <OwlCarousel
              {...settings}
              className="owl-carousel login-slide owl-theme">
              <div className="welcome-login">
                <div className="login-banner">
                  <img
                    src={LoginImg}
                    className="img-fluid"
                    alt="Logo"
                  />
                </div>
                <div className="mentor-course text-center">
                  <h2>
                    Welcome to <br />
                    <img src={logo} className="img-fluid" alt="Logo" />
                  </h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam.
                  </p>
                </div>
              </div>
              <div className="welcome-login">
                <div className="login-banner">
                  <img
                    src={LoginImg}
                    className="img-fluid"
                    alt="Logo"
                  />
                </div>
                <div className="mentor-course text-center">
                  <h2>
                    Welcome to <br />
                    <img src={logo} className="img-fluid" alt="Logo" />
                  </h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam.
                  </p>
                </div>
              </div>
              <div className="welcome-login">
                <div className="login-banner">
                  <img
                    src={LoginImg}
                    className="img-fluid"
                    alt="Logo"
                  />
                </div>
                <div className="mentor-course text-center">
                  <h2>
                    Welcome to <br />
                    <img src={logo} className="img-fluid" alt="Logo" />
                  </h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam.
                  </p>
                </div>
              </div>
            </OwlCarousel>
          </div> */}
          {/* /Login Banner */}
          <div className="col-md-12 login-wrap-bg">
            {/* Login */}
            <div className="login-wrapper custom-login-bg">
              <div className="loginbox">
                <div className="w-100">
                  <div className="img-logo">
                    <img
                      src={logo}
                      className="img-fluid"
                      alt="Logo"
                    />
                    <div className="back-home">

                    </div>
                  </div>
                  <h1>Welkom!</h1>
                  <p>Hoe wil je inloggen?</p>
                  <div className="kenni-btn">
                    <button className="btn btn-primary w-100" onClick={()=>window.open('https://oidcng.entree-s.kennisnet.nl/oidc/authorize?client_id=lmsapi.rigicgspl.com/&redirect_uri=https://lmsapi.rigicgspl.com/api/callback&response_type=code&grant_types=%5B%22implicit%22%5D&scopes=%5B%22openid%22,%22profile%22,%22email%22%5D')}>
                      <svg className="me-2" width="32" height="17" viewBox="0 0 32 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M31.8086 6.81134L30.2288 5.23149C30.1038 5.10655 29.9305 5.03401 29.7492 5.03401H15.2122C13.9185 2.34987 11.1779 0.5 7.99805 0.5C3.58092 0.5 -0.00195312 4.08287 -0.00195312 8.5C-0.00195312 12.9171 3.58092 16.5 7.99805 16.5C11.9074 16.5 15.1598 13.695 15.857 9.99119H15.9376C16.119 9.99119 16.2882 10.0637 16.4172 10.1887L17.997 11.7685C18.263 12.0345 18.6902 12.0345 18.9522 11.7685L20.2499 10.4708C20.5159 10.2048 20.9431 10.2048 21.2051 10.4708L22.5028 11.7685C22.7688 12.0345 23.196 12.0345 23.458 11.7685L24.7557 10.4708C25.0217 10.2048 25.4489 10.2048 25.7109 10.4708L27.0086 11.7685C27.2746 12.0345 27.7018 12.0345 27.9638 11.7685L29.7411 9.99119L31.7965 7.76247C32.0706 7.50454 32.0706 7.07733 31.8086 6.81134ZM4.26606 10.636C3.08924 10.636 2.13407 9.68086 2.13407 8.50403C2.13407 7.3272 3.08924 6.37204 4.26606 6.37204C5.44289 6.37204 6.39805 7.3272 6.39805 8.50403C6.39805 9.68086 5.44692 10.636 4.26606 10.636Z" fill="white" />
                      </svg>

                      Inloggen via  kennisnet</button>
                  </div>
                  <div className="relative">
                    <div className="divider"></div>
                    <div className="or-text">Of</div>
                  </div>
                  <form onSubmit={handlesubmit}>
                    <div className="form-group">
                      <label className="form-control-label">E-mail</label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Voorbeeld@voorbeeld.nl"
                        name="email"
                      />
                    </div>
                    <div className="form-group">
                      <label className="form-control-label">Wachtwoord</label>
                      <div className="pass-group">
                        <input type="password" name="password" className="form-control" placeholder="Voer uw wachtwoord in" />
                        {/* <span className="toggle-password feather-eye" onClick={togglePassword}>
                          {passwordType === "password" ? <FeatherIcon icon="eye" /> : <FeatherIcon icon="eye-off" />}
                        </span> */}
                      </div>
                    </div>

                    <div className="remember-me">
                      <label className="custom_check mr-2 mb-0 d-inline-flex remember-me">
                        {" "}
                        Vergeet mij niet
                        <input type="checkbox" name="radio" />
                        <span className="checkmark" />
                      </label>
                    </div>
                    <div className="text-danger fs-12" ><p style={{ color: 'red' }}>{error}</p></div>
                    <div className="d-grid">
                      <Button


                        className="btn btn-start"
                        type="submit"
                      >
                        Inloggen
                      </Button>
                    </div>
                    <div>
                      <p style={{marginTop: "30px"}}>Geen account? Neem{" "}
                        <button style={{background: "none", border: "none", padding: "0px", color: "#FD4A36"}} onClick={()=>window.open('https://oidcng.entree-s.kennisnet.nl/oidc/authorize?client_id=lmsapi.rigicgspl.com/&redirect_uri=https://lmsapi.rigicgspl.com/api/callback&response_type=code&grant_types=%5B%22implicit%22%5D&scopes=%5B%22openid%22,%22profile%22,%22email%22%5D')}>hier</button>
                        {" "}contact op!
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            {/* /Login */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
