import clientAuth from "../clientAuth";


export default {


   
    student_add: (userdata) => clientAuth.post('teacher/student_add', { json: userdata }),
    student_index: (userdata) => clientAuth.post('teacher/student_index', { json: userdata }),
    teacher_profile: () => clientAuth.get('teacher/teacher_profile'),
    class_index: () => clientAuth.get('teacher/class/index'),
    teacher_profile_update: (userdata) => clientAuth.post('teacher/teacher_profile_update', { json: userdata }),
    changepassword: (userdata) => clientAuth.post('teacher/changepassword', { json: userdata }),
    student_show:(id) => clientAuth.get(`teacher/student_show/${id}`),
    current_level_index:() => clientAuth.get('teacher/level/index'),
    avarage_score_student: (userdata) => clientAuth.post('teacher/avarage/score/student', { json: userdata }),
    // continuity_played_month_score: () => clientAuth.get('teacher/continuity/played/month/score'),
    continuity_played_month_score: (userdata) => clientAuth.post('teacher/continuity/played/month/score', { json: userdata }),
    // played_game_list: () => clientAuth.get('teacher/chart/played/game/list'),
    played_game_list: (userdata) => clientAuth.post('teacher/chart/played/game/list', { json: userdata }),
    student_class_index: () => clientAuth.get('student/class/index'),
    chart_gamewithclass: (userdata) => clientAuth.post('teacher/chart/gamewithclass', { json: userdata }),
    chart_classper_game: (userdata) => clientAuth.post('teacher/chart/classper/game', { json: userdata }),
    school_average_teacher_score: (userdata) => clientAuth.post('teacher/school/average/teacher/score', { json: userdata }),
    school_average_score: (userdata) => clientAuth.post('teacher/school/average/score', { json: userdata }),
    continuity_teacher_score: (userdata) => clientAuth.post('teacher/continuity/teacher/score', { json: userdata }),
    school_continuity_score: (userdata) => clientAuth.post('teacher/school/continuity/score', { json: userdata }),
    student_report: (userdata) => clientAuth.post('teacher/student/reports', { json: userdata }),
    
}