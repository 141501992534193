import React, { useEffect, useState } from "react";
import { InstructorHeader } from "../../../instructor/header";
import InnerBanner from "../../../../assets/img/inner-banner.jpg";
import DetailsContent from "./detailsContent";
import { Icon1 } from "../../../imagepath";
import InstructorSidebar from "../../../instructor/sidebar";
import Footer from "../../../footer";
import { useNavigate, useParams } from "react-router-dom";
import LearningService from "../../../../Api/Services/LearningService";
const CourseDetails = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [learningData, setLearningData] = useState([]);
  
  useEffect(() => {

    if (localStorage.getItem('usertoken')) {
      var teacherdata = JSON.parse(localStorage.getItem("user"));
      if (teacherdata.roles[0].name === "Teacher") {
        learningmodule();
      }
      else {
        navigate('/')
      }

    } else {
      navigate('/login')
    }
  }, [navigate])
  const learningmodule = async () => {
    try {

      let responseData = await LearningService.all_module_with_task().json()

      if (responseData.status === true) {
        var getlearningdata = responseData.Module.filter(function (item) {

          return item.id == params.id;
        });
        setLearningData(getlearningdata)

      }

    } catch (error) {
      console.log(error)
    }
  }
  return (
    <>
      <div className="main-wrapper">
        <InstructorHeader activeMenu={"Earnings"} />
        <div className="page-content instructor-page-content">
          <div className="container-fluid">
            <div className="row">
              {/* Sidebar */}
              <InstructorSidebar activeMenu={"Dashboard"} />
              {/* Sidebar */}

              {/* Instructor Dashboard */}
              {
                learningData.map((element) => {
                  return (
                    <>
                    <div className="col-xl-9 col-lg-8 col-md-12">
                      <div className="inner-banner" style={{ backgroundImage: "url(" + InnerBanner + ")" }}>
                        <div className="container">
                          <div className="row">
                            <div className="col-lg-12">

                              <h2>{element.name}</h2>
                              <p>
                                {element.subtitle}
                              </p>
                              <div className="course-info d-flex align-items-center border-bottom-0 m-0 p-0">
                                <div className="cou-info">
                                  <img src={Icon1} alt="" />
                                  <p>{element.total_lessons}+ Lesson</p>
                                </div>
                                

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <DetailsContent structure={learningData}/>

                    </div>
                    </>
                  )
                })
              }

            </div>
          </div>


        </div>






        <Footer />

      </div>
    </>
  );
};

export default CourseDetails;
