import React, { useState, useEffect } from 'react';
import StudentService from '../../../Api/Services/StudentService';
import InstructorSidebar from "../sidebar/index";
import StudentHeader from "../../student/header";
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import Footer from "../../footer";
const StudentProfile = () => {
    const [Success, setSuccess] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [file, setFile] = useState()
    const [Error, setError] = useState("")
    const navigate = useNavigate();
    const [profiledata, setProfiledata] = useState('');
    var imageUrl = process.env.REACT_APP_URL;
    useEffect(() => {
        if (localStorage.getItem('usertoken')) {

            handleTeacherProfile();


        } else {
            navigate('/login')
        }
    }, [navigate])
    const handleTeacherProfile = async () => {
        try {

            let responseData = await StudentService.student_profile().json()

            if (responseData.status === true) {
                setProfiledata(responseData)



            }

        } catch (error) {
            console.log(error)
        }

    }
    const onFileChange = (e, file) => {
        if (file) {
            setFile("");
            return;
        }
        const fileReader = new FileReader()
        fileReader.readAsDataURL(e.target.files[0])
        fileReader.onload = () => {
            var image = fileReader.result
            setFile(image)
        }
    }
    const addStudent = async (event) => {

        event.preventDefault();
        if (event.target.firstname.value === "") {
            setError("Het veld voornaam is verplicht")
        }
        else if (event.target.lastname.value === "") {
            setError("Het veld achternaam is verplicht")
        }
        else if (event.target.email.value === "") {
            setError("Het e-mailveld is verplicht")
        }
        else {

            try {
                var userData = {
                    "firstname": event.target.firstname.value,
                    "lastname": event.target.lastname.value,
                    "email": event.target.email.value,
                    "image": file ? file.split(',')[1] : ""
                }

                let responseData = await StudentService.student_profile_update(userData).json()
                if (responseData.status === true) {
                    localStorage.setItem("user", JSON.stringify(responseData.data));
                    setSuccess(true)
                    setModalMessage(responseData.message)
                    setError("");

                }
            } catch (error) {

                if (error.name === 'HTTPError') {
                    const errorJson = await error.response.json();
                    setError(errorJson.message.substr(0, errorJson.message.lastIndexOf(".")))
                }
            }
        }

    };
    return (
        <>
            <div className="main-wrapper">
                <StudentHeader activeMenu={"Student"} />
                <div className="instructor-page-content pt-4 mt-1">
                    <div className="container">
                        <div className="row">

                            {/* Sidebar */}
                            <InstructorSidebar activeMenu={"Student"} />
                            <div className="col-xl-9 col-md-8 mt-3 mt-lg-0">
                                <div className='row justify-content-center'>
                                    <div className='col-xl-12'>
                                        <div className="settings-widget profile-details">
                                            <div className='settings-menu p-0'>
                                                <div className='edit-profile'>
                                                    <div className="profile-heading">
                                                        <h4>Jouw gegevens</h4>
                                                    </div>
                                                    <div className="checkout-form personal-address">

                                                        <form onSubmit={addStudent}>
                                                            <div className='row'>
                                                                <div className='col-lg-6'>
                                                                    <div className="form-group text-start">
                                                                        <label className="form-control-label">Voornaam</label>
                                                                        <input
                                                                            type="text"
                                                                            name='firstname'
                                                                            className="form-control"
                                                                            placeholder="Enter Firstname"
                                                                            defaultValue={profiledata.firstname}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className='col-lg-6'>
                                                                    <div className="form-group text-start">
                                                                        <label className="form-control-label">Achternaam </label>
                                                                        <input
                                                                            type="text"
                                                                            name='lastname'
                                                                            className="form-control"
                                                                            placeholder="Enter Lastname"
                                                                            defaultValue={profiledata.lastname}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className='col-lg-6'>
                                                                    <div className="form-group text-start">
                                                                        <label className="form-control-label">Email</label>
                                                                        <input
                                                                            type="email"
                                                                            name='email'
                                                                            className="form-control"
                                                                            placeholder="Enter Email"
                                                                            defaultValue={profiledata.email}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className='col-lg-6'>
                                                                    <div className="form-group text-start">
                                                                        <label className="form-control-label">Foto </label>
                                                                        <div className='upload-img'>
                                                                            {profiledata.image ? <img className="img-drop-area" height={50} width={50} name="image" src={file ? file : imageUrl + "/User/" + profiledata.image} alt='' /> : null}


                                                                            <input type="file" className="form-control" name='icon' id="val-number" accept="image/png, image/jpeg" onChange={(e) => onFileChange(e, file)} />
                                                                        </div>

                                                                    </div>
                                                                </div>

                                                                {Error ? <p className=" text-center items-center justify-between rounded bg-warning py-2 text-lg text-white">{Error}</p> : ''}
                                                                <div className="update-profile ">
                                                                    <button type="submit" className="btn btn-primary">Verzend</button>

                                                                </div>
                                                            </div>
                                                        </form>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div></div>
                </div>
            </div>
            <Modal show={Success}>
                <Modal.Header>
                    <Modal.Title>Save</Modal.Title>
                </Modal.Header>

                <Modal.Body>{modalMessage}</Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            navigate('/student-dashboard')
                            setFile("")
                            setSuccess(false)
                        }}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Footer />
        </>
    );
};

export default StudentProfile;
