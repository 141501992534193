import ReactPlayer from "react-player";
import React, { useEffect, useState, useRef } from "react";
// 
import Footer from "../../footer";
import { useLocation } from "react-router-dom";

import { useNavigate } from "react-router-dom";
import { InstructorHeader } from "../header";
import InstructorSidebar from "../sidebar";

function InstructorReviews() {

  const getDetails = useLocation();
  const propsData = getDetails.state;

  const playerRef = useRef(null);
  const navigate = useNavigate();
  const [lessonData, setLessonData] = useState([]);
  const [NewData, setNewData] = useState([]);
  const [NewTitle, setNewTitle] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(() => {

    if (localStorage.getItem('usertoken')) {
      var teacherdata = JSON.parse(localStorage.getItem("user"));
      if (teacherdata.roles[0].name === "Teacher") {
        var newtext = propsData.lesson.map((element) => { return element.title })
        setNewTitle(newtext)
        var newurl = propsData.lesson.map((element) => { return element.video_url })

        setNewData(newurl)
        setLessonData(propsData.lesson)


      }
      else {
        navigate('/')
      }

    } else {
      navigate('/login')
    }
  }, [navigate])

  const handlePrevClick = () => {

    if (currentIndex > 0) {

      setCurrentIndex(currentIndex - 1);
    }
    else {
      setCurrentIndex(0);

    }
  }
  const handleNextClick = () => {
    var newtext = lessonData.map((element) => { return element.title })
    var newurl = lessonData.map((element) => { return element.video_url })

    setNewData(newurl)
    setNewTitle(newtext)
    if (currentIndex < newtext.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  }
  return (

    <div className="main-wrapper">
      <InstructorHeader activeMenu={"Reviews"} />
      {/* Page Wrapper */}
      <div className="page-content">
        <div className="container">
          <div className="row">
            {/* Sidebar */}
            <InstructorSidebar activeMenu={"Reviews"} />
            {/* Sidebar */}

            {/* Instruction Dashboard */}
            <div className="col-xl-9 col-lg-8 col-md-12">
              <div className="row">
                <div className="col-md-12">
                  <div className="settings-widget">
                    <div className="settings-inner-blk p-0">
                      <div className="sell-course-head comman-space">

                        <h5>{NewTitle[currentIndex]}</h5>
                        <div className="arrow-right">
                          {/* <Link onClick={() => handlePrevClick()}>

                            <i className="fas fa-angles-left"></i>
                          </Link> */}

                          <a onClick={() => handlePrevClick()} >
                              <i className="fas fa-angles-left"></i>
                          </a>

                          <a onClick={() => handleNextClick()}>
                            <i className="fas fa-angles-right"></i></a>
                        </div>

                      </div>
                      <div className="comman-space bdr-bottom-line">
                        <div className="instruct-review-blk ">

                          <ReactPlayer ref={playerRef} url={NewData[currentIndex]} controls={true} className="img-fluid w-100" />

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            {/* Instructor Dashboard */}
          </div>
        </div>
      </div>
      {/* Page Wrapper */}
      <Footer />
    </div>

  );
}
export default InstructorReviews;
